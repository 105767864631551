const initialState = {
  show: false,
  agoraCallsData: "",
  messageType: "",
  showCallerMessage: false,
  message: "",
  callFeedBackShow: false,
  auditId: null,
  teamGroupMembers: [],
  callpatientId: "",
};
const resetState = {
  appId: "",
  channelName: "",
  agoraToken: "",
  initiaterID: "",
  typeofCall: "",
  receiverName: "",
  collapse: false,
  callFeedBackShow: false,
  callpatientId: "",
};
const CallRecucers = (state = initialState, action) => {
  switch (action.type) {
    case "CALL_SHOW":
      return {
        ...state,
        show: action.payload.show,
        auditId: action.payload.auditId
          ? action.payload.auditId
          : state?.auditId,
        agoraCallsData: { ...action.payload },
      };
    case "GROUP_CALL_SHOW":
      return {
        ...state,
        groupCallShow: action.payload.show,
        auditId: action.payload.auditId
          ? action.payload.auditId
          : state?.auditId,
        agoraCallsData: { ...action.payload },
      };
    case "UPDATEGROUPPROVIDERDATA":
      return {
        ...state,
        agoraCallsData: {
          ...state.agoraCallsData,
          groupCallProviderData: [
            ...state.agoraCallsData.groupCallProviderData,
            action.payload,
          ],
        },
      };
    case "GROUP_TEAM_MEMBERS":
      return {
        ...state,
        teamGroupMembers: action.payload,
      };
    case "CallerReject":
    case "No Answering":
    case "callDisconnected":
      return {
        ...state,
        messageType: action.type,
        showCallerMessage: action.showCallerMessage,
        message: action.message,
      };
    case "reset":
      return {
        ...state,
        messageType: "",
        showCallerMessage: false,
        message: "",
      };
    case "resetCall":
      return {
        ...state,
        show: false,
        groupCallShow: false,
        agoraCallsData: resetState,
      };
    case "callFeedBackShow":
      return {
        ...state,
        callFeedBackShow: true,
      };
    case "callFeedBackHide":
      return {
        ...state,
        callFeedBackShow: false,
        auditId: null,
      };
    case "patientCall":
      return {
        ...state,
        callpatientId: action.payload,
      };
    default:
      return state;
  }
};

export default CallRecucers;
