import produce from "immer";

const INITIAL_STATE = {
  teamdetails: [],
};

export default produce((draft, action) => {
  if (typeof draft === "undefined") {
    return INITIAL_STATE;
  } else if (action.type === "ADDTEAMDETAILS") {
    draft.teamdetails = action.payload;
  }
});
