import axios from "axios";
import { toast } from "react-toastify";
import {
  LocalStorageClear,
  LocalStorageSetValue,
  LocalStorageGetValue,
} from "../../Services/Auth";

export const HealthMonitorCheck = async (self) => {
  const token = LocalStorageGetValue("token");
  const id = JSON.parse(LocalStorageGetValue("id"));
  try {
    if (self.state.sendHealthMonitorDisable) {
      throw new Error("sendHealthMonitorDisabled");
    }
    const response = await axios.post(
      `/api/providerEndpoints/v1/sendHealthMonitorEvent`,
      {
        providerId: id,
        token,
        screenName: "MainLayout",
      }
    );
    if (self.state.sendHealthMonitorDisable) {
      throw new Error("sendHealthMonitorDisabled");
    }
    if (
      !response.data.status &&
      response.data.errorId === 0 &&
      response.data.title === "Session Invalidated" &&
      response.data.errorMessage ===
        "You have been logged out by the system administrator for performing system maintenance. Please log back in after some time."
    ) {
      self.setState({
        invalidateTitle: response.data.title,
        invalidateMessage: response.data.errorMessage,
        activeOnAnotherDevice: false,
        kickout: true,
      });
    }
    if (!response.data.status && response.data.errorId === 102) {
      self.props.resetCall();
      self.setState({
        invalidateTitle: response.data.title,
        invalidateMessage: response.data.errorMessage,
        activeOnAnotherDevice: true,
      });
    }

    if (!response.data.status && response.data.errorId === 101) {
      self.props.resetCall();
      self.setState({
        deniedusertitle: response.data.title,
        deniedusermessage: response.data.errorMessage,
        providerDenied: true,
      });
    }

    if (response.data.errorId === 104) {
      self.setState({
        authenticateModel: true,
        disabled: true,
        currentAPIRunning: false,
      });
    }
    if (
      !response.data.status &&
      (response.data.errorId === 106 || response.data.errorId === 103)
    ) {
      toast.warning(response.data.errorMessage);
      LocalStorageClear();
      sessionStorage.removeItem("currentPath");
      self.setState({ lockedUserModel: true });
    }
    if (response.data.title === "Session Timeout") {
      self.setState({ sessionTimeOutModel: true });
      self.setState({ sessionTimeOutMessage: response.data.errorMessage });
    }
  } catch (error) {
    if (error && self) {
      if (error.message === "sendHealthMonitorDisabled") {
        return;
      }
      self.setState({ currentAPIRunning: false });
      if (
        error.response.status !== null &&
        error.response.status !== undefined
      ) {
        if ([504, 500, 503, 404].includes(error.response.status)) {
          toast.warning(error);
        }
        if ([401].includes(error.response.status)) {
          if (self.state.invalidateMessage !== "Password has been changed") {
            LocalStorageClear();
            sessionStorage.removeItem("currentPath");
            self.props.history.push("/login");
            window.history.replaceState(null, "", "/");
          }
        }
        if ([703, 502].includes(error.response.status)) {
          refreshTokenUpdates(self);
        }
        if ([704].includes(error.response.status)) {
          self.props.resetCall();
          LocalStorageClear();
          sessionStorage.removeItem("currentPath");
          self.props.history.push("/login");
          window.history.replaceState(null, "", "/");
        }
      }
    }
  }
};
export const refreshTokenUpdates = async (self) => {
  let sAvailRefresh = LocalStorageGetValue("rToken");
  let passingObject = {
    token: sAvailRefresh,
  };
  try {
    const response = await axios.post(
      `/api/userEndpoints/v1/renewIdToken`,
      passingObject
    );
    const firebaseTokenTemp = response.data.idToken;
    const rTokenTemp = response.data.refreshToken;
    const idTokenTemp = response.data.idToken;
    LocalStorageSetValue("firebaseToken", firebaseTokenTemp);
    LocalStorageSetValue("rToken", rTokenTemp);
    LocalStorageSetValue("idToken", idTokenTemp);
    axios.defaults.headers.common["Authorization"] = idTokenTemp;
    if (self !== null || self !== undefined) {
      HealthMonitorCheck(self);
    }
  } catch (error) {
    LocalStorageClear();
    sessionStorage.removeItem("currentPath");
    self.props.history.push("/login");
    window.history.replaceState(null, "", "/");
  }
};

export const HealthMonitorCheckOnCalls = async () => {
  const token = LocalStorageGetValue("token");
  const id = JSON.parse(LocalStorageGetValue("id"));
  try {
    await axios.post(`/api/providerEndpoints/v1/sendHealthMonitorEvent`, {
      providerId: id,
      token,
      screenName: "MainLayout",
    });
  } catch (error) {
    if (error) {
      if (
        error.response.status !== null &&
        error.response.status !== undefined &&
        [703, 502].includes(error.response.status)
      ) {
        refreshTokenUpdates(null);
      }
    }
  }
};
