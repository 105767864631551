import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/styles";
import Firebase from "firebase";
import PropTypes from "prop-types";
import { DISCONNECTED } from "../../constant";
import Ringtone from "../../Assets/images/ringtone.mp3";
import { connect } from "react-redux";
import {
  showmodel,
  showMessage,
  showGroupModel,
} from "../../Redux/Actions/callActions";
import Answer from "../../Assets/images/callAtten.png";
import CallEnd from "../../Assets/images/callEnd.png";
import { toast } from "react-toastify";
import { incomingCallAction } from "../../Redux/Actions/incomingCallAction";
import { LocalStorageGetValue } from "../../Services/Auth";
import {
  Typography,
  Button,
  Snackbar,
  IconButton,
  Avatar,
  Divider,
  Grid,
} from "@material-ui/core";
import {
  getAgoraToken,
  checkingProviderStatus,
} from "../../Services/AudVidCalls/AudVidCalls";
import {
  groupCallDisconnect,
  groupCallNotAnswered,
  callDisconnectsaveCallAudit,
  callConnectsaveCallAudit,
} from "../../Services/GroupCall/GroupCall";
const styles = (theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    textAlign: "center",
    background:
      "linear-gradient(to right, #009fff, #ec2f4b)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
  },
  snackbarStyleViaContentProps: {
    backgroundColor: "white",
    color: "black",
    display: "flex",
    flexDirection: "column",
  },
});
const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
};
class SnackbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      Transition: Fade,
      notification: null,
      msg: "",
      notifyonFocus: true,
      clicked: false,
      msgs: null,
      call: false,
      chat: false,
      type: "",
      agoraInitial: false,
      agoraAppId: "ac7f54fc9e7e461aae23a07312afb431",
      agoraChannelId: "",
      agoraToken: "",
      agoraUID: null,
      providerIdDetails: "",
      messageType: "",
      videocall: false,
      audiocall: false,
      soscall: false,
      callDisconnected: false,
      noAnswering: false,
      message: "",
      sosSnackbar: false,
      videoCallonFocus: true,
      openCallModal: true,
      callInProgress: false,
      enableSnackBar: false,
      caller1: "",
      audio: new Audio(Ringtone),
      receiverAudio: "",
    };
    this.leavecall = React.createRef();
  }
  playSoundChat = () => {
    const audio = new Audio(
      "http://codeskulptor-demos.commondatastorage.googleapis.com/pang/pop.mp3"
    );
    audio.play();
    setTimeout(() => {
      audio.pause();
    }, 2000);
  };
  componentDidMount() {
    let info = "";
    if (
      this.state.messageType === "" &&
      this.state.agoraInitial === false &&
      this.props.callProgress === false
    ) {
      if (this.props.notification) {
        info = JSON.parse(this.props.notification.Provider);
      }
      if (this.props.type === "new_message") {
        this.setState({ chat: true });
      } else if (this.props.type === "callDisconnected") {
        this.setState({ callDisconnected: true });
      } else if (
        this.props.type === "No Answering" ||
        this.props.type === "CallerNotAnswer"
      ) {
        this.setState({ noAnswering: true });
      } else if (this.props.type === "sos_dismiss") {
        this.setState({ soscall: false }, () => {
          this.nullFirebaseDB();
        });
      } else if (this.props.type === "CallerReject") {
        if (
          this.props.notification.message !== "LASTCONNECTION" &&
          this.props.notification.message !== "DISCONNECTED" &&
          this.props.notification.message !== "DISCONNECTED"
        ) {
          this.setState({ chat: true });
        } else {
          this.setState({
            chat: true,
            videocall: false,
            audiocall: false,
            soscall: false,
          });
          this.leavecall.current.leaveCall();
        }
      } else if (this.props.type === "video_call") {
        let receiverAudio = setInterval(() => {
          this.state.audio.play();
        }, 2000);
        this.setState({ receiverAudio, videocall: true });
      } else if (this.props.type === "SOS") {
        let receiverAudio = setInterval(() => {
          this.state.audio.play();
        }, 2000);
        this.setState({ receiverAudio, soscall: true });
      } else if (this.props.type === "audio_call") {
        let receiverAudio = setInterval(() => {
          this.state.audio.play();
        }, 2000);
        this.setState({ receiverAudio, audiocall: true });
      }
      this.setState({
        messageType: this.props.type,
        message: this.props.message,
        caller1: info.id,
      });
      this.setState({
        notification: this.props.notification,
        callInProgress: true,
        enableSnackBar: true,
      });
    } else {
      if (this.props.type === "new_message") {
        this.setState({ chat: true });
      } else if (this.props.type === "CallerReject") {
        if (
          this.props.notification.message !== "LASTCONNECTION" &&
          this.props.notification.message !== "DISCONNECTED" &&
          this.props.notification.message !== "DISCONNECTED"
        ) {
          this.setState({ chat: true });
        } else {
          this.setState({
            chat: false,
            videocall: false,
            audiocall: false,
            soscall: false,
          });
          this.leavecall.current.leaveCall();
        }
      } else if (this.props.type === "video_call") {
        this.setState({ videocall: true });
      } else if (
        this.props.type === "No Answering" ||
        this.props.type === "CallerNotAnswer"
      ) {
        this.setState({ noAnswering: true });
      } else if (this.props.type === "audio_call") {
        this.setState({ audiocall: true });
      } else if (this.props.type === "SOS") {
        this.setState({ soscall: true });
      }
      this.setState({
        agoraInitial: false,
        messageType: this.props.type,
        message: this.props.message,
        notification: this.props.notification,
        callInProgress: true,
        enableSnackBar: true,
      });
    }
  }
  nullFirebaseDB = () => {
    const userDetails = JSON.parse(LocalStorageGetValue("user"));
    const dbRef1 = Firebase.database().ref("providers/");
    dbRef1.child(userDetails.uid).child("notification").set(null);
  };
  componentDidUpdate(prevState) {
    if (this.state.notification != null) {
      let caller2 = JSON.parse(this.props.notification.Provider);
      if (this.state.caller1 !== caller2.id) {
        if (this.props.type === "video_call") {
          this.setState({
            caller1: caller2.id,
            open: this.props.open,
            enableSnackBar: true,
            videocall: true,
            messageType: this.props.type,
            message: this.props.message,
          });
        }
        if (this.props.type === "audio_call") {
          this.setState({
            caller1: caller2.id,
            open: this.props.open,
            enableSnackBar: true,
            audiocall: true,
            messageType: this.props.type,
            message: this.props.message,
          });
        }
      }
    }
  }
  sendMsg = (Transition) => {
    this.setState({
      ...this.state,
      open: false,
      msg: "",
    });
  };

  handleClick = () => () => {
    if (this.props.chat) {
      this.setState({
        open: true,
        notification: false,
        call: false,
        chat: true,
        SlideTransition,
        msg: "Reply my message",
      });
    } else if (this.props.call) {
      this.setState({
        open: true,
        notification: false,
        chat: false,
        call: true,
        SlideTransition,
        msg: "pickup my call",
      });
    }
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };
  handleCloses = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      ...this.state,
      open: false,
    });
    this.props?.handleGroupCallFlag?.();
  };
  handleClosess = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      ...this.state,
      open: false,
    });
    this.props?.handleGroupCallFlag?.();
  };
  handleCloseCallDis = () => {
    this.state.audio.pause();
    clearInterval(this.state.receiverAudio);
    this.nullFirebaseDB();
  };
  notifyFocus = () => {
    this.setState({ notifyFocus: false });
  };
  componentWillUnmount() {
    this.state.audio.pause();
    clearInterval(this.state.receiverAudio);
  }
  joinCall = async (e) => {
    this.state.audio.pause();
    e.stopPropagation();
    clearInterval(this.state.receiverAudio);
    this.nullFirebaseDB();
    this.props.handleGroupCallFlag();
    let agoraCallsData = {};
    Promise.resolve().then(async () => {
      agoraCallsData = {
        appId: "",
        agoraToken: "",
        channelName: "",
        typeofCall: "",
        initiaterID: "",
        show: false,
        auditId: "",
        callInitiaterDetails: "",
      };
      this.props.openCallWindow(agoraCallsData);
    });
    let details = JSON.parse(this.props.notification.Provider);
    let remoteUserId = JSON.parse(this.props.notification.Provider);
    let receiverId = remoteUserId.id;
    let profilePicUrl = details["profilePicUrl"];
    //let splitName = details.name.split(" ");
    // let twoLetters =
    //   splitName[0].replace(/[\s'.-]/g, "")[0].toUpperCase() +
    //   splitName[1].replace(/[\s'.-]/g, "")[0].toUpperCase();
    let twoLetters =
      details.fname.replace(/[\s'.-]/g, "")[0].toUpperCase() +
      details.lname.replace(/[\s'.-]/g, "")[0].toUpperCase();
    let name =
      details.name.length > 20
        ? details.name.slice(0, 20) + "..."
        : details.name;
    let hospital = details.remoteProviderType.includes("Bedside Clinician")
      ? details?.displayName || "Bedside Clinician"
      : "Remote Clinician";
    let userDetails = JSON.parse(LocalStorageGetValue("userDetails"));
    let callInitiaterFirst = userDetails["fname"];
    let callInitiaterLast = userDetails["lname"];
    let callInitPicture = userDetails["profilePicUrl"];
    let callInitiaterTwoLetters =
      callInitiaterFirst.replace(/[\s'.-]/g, "")[0] +
      callInitiaterLast.replace(/[\s'.-]/g, "")[0];
    let callInitiaterName =
      userDetails["name"].length > 10
        ? userDetails["name"].slice(0, 10) + "..."
        : userDetails["name"];
    let initRole =
      userDetails["role"] != "BD"
        ? "," + userDetails?.displayName || userDetails["remoteProviderType"]
        : "";
    callInitiaterName = callInitiaterName + initRole;
    let callInitiaterRole =
      userDetails["remoteProviderType"] === undefined
        ? ""
        : userDetails["displayName"] || userDetails["remoteProviderType"];
    let roleCall = details.remoteProviderType.includes("Bedside Clinician")
      ? ""
      : details.displayName || details.remoteProviderType;
    //need to change after db cleanup
    /*let callInitiaterHospital =
      userDetails["hospital"] === "Remote Side Provider" ||
      userDetails["hospital"] === "Remote Clinician"
        ? "Remote Clinician"
        : "Bedside Clinician";*/
    let callInitiaterHospital =
      userDetails["role"] === "RD"
        ? "Remote Clinician"
        : userDetails["role"] === "BD"
        ? userDetails["displayName"] || "Bedside Clinician"
        : "";
    let callInitiaterDetails = {
      callInitiaterTwoLetters,
      callInitiaterName,
      callInitiaterRole,
      callInitiaterHospital,
      callInitPicture,
    };
    let auditId = this.props.notification.auditId;
    this.setState({ videoCallonFocus: false, nonClick: true });
    let id = LocalStorageGetValue("id");
    let data = {
      channelName: this.props.notification.message,
      uid: JSON.parse(id),
    };
    this.setState({ open: false });
    await callConnectsaveCallAudit(auditId);
    getAgoraToken(data).then((response) => {
      if (response.data.status) {
        this.setState({
          agoraInitial: true,
          type: this.props.type,
          agoraToken: response.data.errorMessage,
          agoraChannelId: this.props.notification.message,
          agoraUID: JSON.parse(id),
          message: "",
          videoCallonFocus: true,
          enableSnackBar: false,
        });
        let reciveName = JSON.parse(this.props.notification.Provider);
        let patient;
        if (this.props.notification?.Patient) {
          patient = JSON.parse(this.props.notification?.Patient);
        }
        agoraCallsData = {
          appId: JSON.stringify(LocalStorageGetValue("agoraAppId")),
          channelName: this.props.notification.message,
          agoraToken: response.data.errorMessage,
          initiaterID: JSON.parse(id),
          typeofCall: this.props.type,
          show: true,
          twoLetters,
          receiverId,
          auditId,
          roleCall,
          callInitiaterRole,
          hospital,
          profilePicUrl,
          name,
          receiverName: reciveName.name,
          callInitiaterDetails,
          patientId: patient ? patient?.patientId : "",
        };
        if (this.props.onetooneCallStatus || this.props.groupCallStatus) {
          Promise.resolve()
            .then(async () => {
              let agoraCallsData_init = {
                appId: "",
                channelName: "",
                agoraToken: "",
                initiaterID: "",
                typeofCall: "",
                show: false,
                receiverName: "",
              };
              if (this.props.onetooneCallStatus) {
                this.props.openCallWindow(agoraCallsData_init);
              } else if (this.props.groupCallStatus) {
                await groupCallDisconnect(
                  String(this.state.notification.auditId),
                  String(this.props.notification.message),
                  Number(this.props.notification.message)
                );
                await callDisconnectsaveCallAudit(
                  String(this.state.notification.auditId)
                );
                this.props.openGroupCallWindow(agoraCallsData_init);
              }
            })
            .then(() => {
              if (this.props.notification.message.includes("-")) {
                this.props.openCallWindow(agoraCallsData);
              } else {
                agoraCallsData.groupCallProviderData = JSON.parse(
                  this.state.notification.ProviderList
                );
                agoraCallsData.auditId = this.state.notification.auditId;
                agoraCallsData.callInitiated = true;
                agoraCallsData.remoteCallInitiator =
                  this.state.notification.Provider;
                agoraCallsData.patientId = this.state.notification.patientId;
                agoraCallsData.teamName = this.state.notification.teamName;
                this.props.openGroupCallWindow(agoraCallsData);
              }
            });
        } else {
          if (this.props.notification.message.includes("-")) {
            this.props.openCallWindow(agoraCallsData);
          } else {
            agoraCallsData.groupCallProviderData = JSON.parse(
              this.state.notification.ProviderList
            );
            agoraCallsData.auditId = this.state.notification.auditId;
            agoraCallsData.callInitiated = true;
            agoraCallsData.remoteCallInitiator =
              this.state.notification.Provider;
            agoraCallsData.patientId = this.state.notification.patientId;
            agoraCallsData.teamName = this.state.notification.teamName;
            this.props.openGroupCallWindow(agoraCallsData);
          }
        }
      }
    });
  };
  joinSOSCall = async (e) => {
    e.stopPropagation();
    this.state.audio.pause();
    let agoraCallsData = {};
    Promise.resolve().then(async () => {
      agoraCallsData = {
        appId: "",
        agoraToken: "",
        channelName: "",
        typeofCall: "",
        initiaterID: "",
        show: false,
        auditId: "",
        callInitiaterDetails: "",
      };
      this.props.openCallWindow(agoraCallsData);
    });
    clearInterval(this.state.receiverAudio);
    let details = JSON.parse(this.props.notification.Provider);
    let patient = JSON.parse(this.props.notification.Patient);
    let receiverId = details["id"];
    let profilePicUrl = details["profilePicUrl"];
    //let splitName = details.name.split(" ");
    // let twoLetters =
    //   splitName[0].replace(/[\s'.-]/g, "")[0].toUpperCase() +
    //   splitName[1].replace(/[\s'.-]/g, "")[0].toUpperCase();
    let twoLetters =
      details.fname.replace(/[\s'.-]/g, "")[0].toUpperCase() +
      details.lname.replace(/[\s'.-]/g, "")[0].toUpperCase();
    let name =
      details.name.length > 20
        ? details.name.slice(0, 20) + "..."
        : details.name;
    let hospital = details.remoteProviderType.includes("Bedside Clinician")
      ? details.remoteProviderType
      : "Remote Clinician";
    let receiverDetails = {
      receiverId,
      name,
      hospital,
      twoLetters,
      profilePicUrl,
    };
    let userDetails = JSON.parse(LocalStorageGetValue("userDetails"));
    let callInitiaterFirst = userDetails["fname"];
    let callInitiaterLast = userDetails["lname"];
    let callInitPicture = userDetails["profilePicUrl"];
    let callInitiaterTwoLetters =
      callInitiaterFirst.replace(/[\s'.-]/g, "")[0] +
      callInitiaterLast.replace(/[\s'.-]/g, "")[0];
    let callInitiaterName =
      userDetails["name"].length > 10
        ? userDetails["name"].slice(0, 10) + "..."
        : userDetails["name"];
    let initRole =
      userDetails["role"] != "BD"
        ? "," +
          (userDetails["displayName"] || userDetails["remoteProviderType"])
        : "";
    callInitiaterName = callInitiaterName + initRole;
    let callInitiaterRole =
      userDetails["remoteProviderType"] === undefined
        ? ""
        : userDetails["displayName"] || userDetails["remoteProviderType"];
    //need to change after db cleanup
    /*let callInitiaterHospital =
      userDetails["hospital"] === "Remote Side Provider" ||
      userDetails["hospital"] === "Remote Clinician"
        ? "Remote Clinician"
        : "Bedside Clinician";*/
    let callInitiaterHospital =
      userDetails["role"] === "RD"
        ? "Remote Clinician"
        : userDetails["role"] === "BD"
        ? details?.displayName || "Bedside Clinician"
        : "";
    let callInitiaterDetails = {
      callInitiaterTwoLetters,
      callInitiaterName,
      callInitiaterRole,
      callInitiaterHospital,
      callInitPicture,
    };
    let auditId = this.props.notification.auditId;
    await callConnectsaveCallAudit(auditId);
    this.setState({ videoCallonFocus: false, nonClick: true });
    let id = LocalStorageGetValue("id");
    let data = {
      channelName: patient.id.toString(),
      uid: JSON.parse(id),
      auditId,
    };
    this.setState({ open: false, messageType: "" });
    getAgoraToken(data).then((response) => {
      if (response.data.status) {
        this.setState({
          agoraInitial: true,
          type: this.props.type,
          agoraToken: response.data.errorMessage,
          agoraChannelId: this.props.notification.message,
          agoraUID: JSON.parse(id),
          message: "",
          videoCallonFocus: true,
          enableSnackBar: false,
        });
        agoraCallsData = {
          appId: JSON.stringify(LocalStorageGetValue("agoraAppId")),
          agoraToken: response.data.errorMessage,
          channelName: patient.id.toString(),
          typeofCall: "video_call",
          initiaterID: JSON.parse(id),
          receiverDetails,
          show: true,
          sosCalls: true,
          sosCallInitiator: false,
          auditId,
          callInitiaterDetails,
        };
        this.props.triggerSOSCAlls(agoraCallsData);
      } else if (
        response.data.errorMessage ==
        "SOS call already accepted by another remote provider"
      ) {
        toast.warn(response.data.errorMessage);
      }
    });
  };
  declineSOS = () => {
    this.state.audio.pause();
    clearInterval(this.state.receiverAudio);
    this.setState({ videoCallonFocus: false, open: false });
    this.setState({
      agoraInitial: false,
      agoraToken: "",
      agoraChannelId: "",
      type: "",
      messageType: "",
      message: "",
      videoCallonFocus: true,
    });
    this.nullFirebaseDB();
    let callStatus = {
      incomingCallStatus: false,
    };
    callDisconnectsaveCallAudit(this.props.notification.auditId);
    this.props.isIncoming(callStatus);
  };
  decline = () => {
    this.state.audio.pause();
    const testToken = LocalStorageGetValue("token");
    const ids = JSON.parse(LocalStorageGetValue("id"));
    const remoteUserId = JSON.parse(this.props.notification.Provider);
    let patient;
    if (this.props.notification?.Patient) {
      patient = JSON.parse(this.props.notification?.Patient);
    }
    this.props.handleGroupCallFlag();
    let auditId = this.props.notification.auditId;
    const url = `api/providerEndpoints/v1/sendMessage`;
    const params = {
      providerId: ids,
      patientId: patient ? patient?.patientId : "",
      token: testToken.replace(/"/g, ""),
      receiverId: remoteUserId.id,
      message: DISCONNECTED,
      auditId,
      type: "CallerReject",
    };
    clearInterval(this.state.receiverAudio);
    this.setState({ videoCallonFocus: false, open: false });
    if (this.state.notification.message.includes("-")) {
      checkingProviderStatus(url, params).then();
      callDisconnectsaveCallAudit(this.props.notification.auditId);
    } else {
      const { auditId, message } = this.state.notification;
      groupCallDisconnect(String(auditId), String(message), Number(message));
    }
    this.setState({
      agoraInitial: false,
      agoraToken: "",
      agoraChannelId: "",
      type: "",
      messageType: "",
      message: "",
      videoCallonFocus: true,
    });
    this.nullFirebaseDB();
    let callStatus = {
      incomingCallStatus: false,
    };
    this.props.isIncoming(callStatus);
  };
  renderNewMessageSanckbar = (type, open, message) => {
    const { classes } = this.props;
    if (type === "new_message") {
      return (
        <Snackbar
          open={this.state.open}
          onClose={this.handleClose}
          style={{ zIndex: 1401 }}
          TransitionComponent={this.state.Transition}
          key={this.state.Transition.name}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps,
          }}
          message={message}
          action={
            <div>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
      );
    }
  };
  renderSOSCallSanckbar = (type, open, message) => {
    const { classes } = this.props;
    const details = JSON.parse(this.props.notification.Provider);
    let profilePicUrl = details["profilePicUrl"];
    //const splitName = details.name.split(" ");
    // const combineLetters =
    //   splitName[0].replace(/[\s'.-]/g, "")[0].toUpperCase() +
    //   splitName[1].replace(/[\s'.-]/g, "")[0].toUpperCase();
    const combineLetters =
      details.fname.replace(/[\s'.-]/g, "")[0].toUpperCase() +
      details.lname.replace(/[\s'.-]/g, "")[0].toUpperCase();
    if (this.state.open) {
      setTimeout(() => {
        if (this.state.open) {
          this.setState({ soscall: false, open: false }, () => {
            this.setState({ open: false });
            this.setState({
              agoraInitial: false,
              agoraToken: "",
              agoraChannelId: "",
              type: "",
              messageType: "",
              message: "",
              videoCallonFocus: true,
            });
            this.nullFirebaseDB();
          });
        }
        let callStatus = {
          incomingCallStatus: false,
        };
        this.props.isIncoming(callStatus);
      }, 50000);
    }
    if (type === "SOS") {
      return (
        <div className="audio_call_snackbar">
          <Snackbar
            style={{ zIndex: 1401 }}
            open={this.state.open}
            onClose={() => this.handleCloses}
            TransitionComponent={this.state.Transition}
            key={this.state.Transition.name}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            ContentProps={{
              "aria-describedby": "message-id",
              className: classes.snackbarStyleViaContentProps,
            }}
            message={
              <Grid
                container
                spacing={2}
                style={{
                  background: "#172B4D",
                  borderRadius: "15px",
                  height: "308px",
                  width: "254px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className="header-h7-light">
                    Incoming SOS Call
                  </Typography>
                  <div className="snackbarAvator">
                    <div className="snackbarSecondaryAvator">
                      <Avatar
                        alt="avator"
                        style={{
                          height: "44.77px",
                          width: "44.45px",
                          alignSelf: "center",
                          background: "white",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            color: "#8044b9",
                            fontSize: "22px",
                            fontWeight: "bold",
                          }}
                        >
                          {profilePicUrl != null ? (
                            <img
                              width="50px"
                              height="50px"
                              src={profilePicUrl}
                            />
                          ) : (
                            combineLetters
                          )}
                        </Typography>
                      </Avatar>
                    </div>
                  </div>
                  {details.remoteProviderType.includes("Bedside Clinician") && (
                    <Typography
                      style={{ marginTop: "12px" }}
                      className="content-emphasized-sidenav-text"
                    >
                      {details.name.length > 17
                        ? details.name.slice(0, 17) + "..."
                        : details.name}{" "}
                    </Typography>
                  )}
                  {details.remoteProviderType.includes("Bedside Clinician") && (
                    <Typography
                      style={{
                        marginTop: "12px",
                        textAlign: "center",
                        overflowWrap: "break-word",
                      }}
                      className="content-emphasized-sidenav-text"
                    >
                      {details?.displayName ||
                        details.remoteProviderType?.replace(
                          "Provider",
                          "Clinician"
                        )}{" "}
                    </Typography>
                  )}
                  {!details.remoteProviderType.includes(
                    "Bedside Clinician"
                  ) && (
                    <Typography
                      style={{
                        marginTop: "12px",
                        textAlign: "center",
                        overflowWrap: "break-word",
                      }}
                      className="content-emphasized-sidenav-text"
                    >
                      {details.name.length > 17
                        ? details.name.slice(0, 17) + "..."
                        : details.name}
                      , {details?.displayName || details.remoteProviderType}
                    </Typography>
                  )}
                  {!details.remoteProviderType.includes(
                    "Bedside Clinician"
                  ) && (
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Remote Clinician
                    </Typography>
                  )}
                </Grid>
                <Divider style={{ border: "1px solid #5E6C84" }} />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    borderTop: "1px solid #5E6C84",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6}>
                      <Button
                        onClick={this.joinSOSCall}
                        fullWidth
                        aria-label="accept"
                        style={{
                          background: "#27AE60",
                          borderRadius: "8px",
                          height: "40px",
                        }}
                      >
                        <img src={Answer} alt="Calling" />
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Button
                        onClick={this.declineSOS}
                        fullWidth
                        aria-label="decline"
                        style={{
                          background: "#E52727",
                          borderRadius: "8px",
                          height: "40px",
                        }}
                      >
                        <img src={CallEnd} alt="Call" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </div>
      );
    }
  };
  renderVideoCallSanckbar = (type, open, message) => {
    const { classes } = this.props;
    const details = JSON.parse(this.props.notification.Provider);
    let profilePicUrl = details["profilePicUrl"];
    //const splitName = details.name.split(" ");
    let auditId = this.props.notification.auditId;
    // const combineLetters =
    //   splitName[0].replace(/[\s'.-]/g, "")[0].toUpperCase() +
    //   splitName[1].replace(/[\s'.-]/g, "")[0].toUpperCase();
    const combineLetters =
      details.fname.replace(/[\s'.-]/g, "")[0].toUpperCase() +
      details.lname.replace(/[\s'.-]/g, "")[0].toUpperCase();
    if (this.state.open) {
      setTimeout(() => {
        if (this.state.open) {
          this.setState({ videocall: false, open: false }, () => {
            const testToken1 = LocalStorageGetValue("token");
            const ids = JSON.parse(LocalStorageGetValue("id"));
            this.setState({ open: false });
            const remoteUserIds = JSON.parse(this.props.notification.Provider);
            let patient;
            if (this.props.notification?.Patient) {
              patient = JSON.parse(this.props.notification?.Patient);
            }
            const urlNoAns = `api/providerEndpoints/v1/sendMessage`;
            const params = {
              providerId: ids,
              patientId: patient ? patient?.patientId : "",
              token: testToken1.replace(/"/g, ""),
              receiverId: remoteUserIds.id,
              message: DISCONNECTED,
              auditId,
              type: "No Answering",
            };
            if (this.state.notification.message.includes("-")) {
              checkingProviderStatus(urlNoAns, params).then();
            } else {
              const { Provider } = this.props.notification;
              const { id: receiverId } = JSON.parse(Provider);

              groupCallNotAnswered(
                String(this.props.notification.auditId),
                String(this.props.notification.message),
                Number(this.props.notification.message),
                Number(receiverId)
              );
            }
            //checkingProviderStatus(urlNoAns, params).then();
            this.setState({
              agoraInitial: false,
              agoraToken: "",
              agoraChannelId: "",
              type: "",
              messageType: "",
              message: "",
              videoCallonFocus: true,
            });
            this.nullFirebaseDB();
          });

          this.props.handleGroupCallFlag();
        }
        let callStatus = {
          incomingCallStatus: false,
        };
        this.props.isIncoming(callStatus);
      }, 50000);
    }
    if (type === "video_call") {
      return (
        <div className="audio_call_snackbar">
          <Snackbar
            style={{ zIndex: 1401 }}
            open={this.state.open}
            onClose={() => this.handleCloses}
            TransitionComponent={this.state.Transition}
            key={this.state.Transition.name}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            ContentProps={{
              "aria-describedby": "message-id",
              className: classes.snackbarStyleViaContentProps,
            }}
            message={
              <Grid
                container
                spacing={2}
                style={{
                  background: "#172B4D",
                  borderRadius: "15px",
                  height: "308px",
                  width: "254px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className="header-h7-light">
                    Incoming Video Call
                  </Typography>
                  <div className="snackbarAvator">
                    <div className="snackbarSecondaryAvator">
                      <Avatar
                        alt="avator"
                        style={{
                          height: "44.77px",
                          width: "44.45px",
                          alignSelf: "center",
                          background: "white",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            color: "#8044b9",
                            fontSize: "22px",
                            fontWeight: "bold",
                          }}
                        >
                          {profilePicUrl != null ? (
                            <img
                              width="50px"
                              height="50px"
                              src={profilePicUrl}
                            />
                          ) : (
                            combineLetters
                          )}
                        </Typography>
                      </Avatar>
                    </div>
                  </div>
                  {details.remoteProviderType.includes("Bedside Clinician") && (
                    <Typography
                      style={{
                        marginTop: "12px",
                        textAlign: "center",
                        overflowWrap: "break-word",
                      }}
                      className="content-emphasized-sidenav-text"
                    >
                      {details.name.length > 17
                        ? details.name.slice(0, 17) + "..."
                        : details.name}{" "}
                    </Typography>
                  )}
                  {details.remoteProviderType.includes("Bedside Clinician") && (
                    <Typography
                      style={{
                        marginTop: "12px",
                        textAlign: "center",
                        overflowWrap: "break-word",
                      }}
                      className="content-emphasized-sidenav-text"
                    >
                      {details.displayName ||
                        details.remoteProviderType?.replace(
                          "Provider",
                          "Clinician"
                        )}{" "}
                    </Typography>
                  )}
                  {!details.remoteProviderType.includes(
                    "Bedside Clinician"
                  ) && (
                    <Typography
                      style={{ marginTop: "12px" }}
                      className="content-emphasized-sidenav-text"
                    >
                      {details.name.length > 17
                        ? details.name.slice(0, 17) + "..."
                        : details.name}
                      , {details?.displayName || details.remoteProviderType}
                    </Typography>
                  )}
                  {!details.remoteProviderType.includes(
                    "Bedside Clinician"
                  ) && (
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Remote Clinician
                    </Typography>
                  )}
                </Grid>
                <Divider style={{ border: "1px solid #5E6C84" }} />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    borderTop: "1px solid #5E6C84",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6}>
                      <Button
                        onClick={this.joinCall}
                        fullWidth
                        aria-label="accept"
                        style={{
                          background: "#27AE60",
                          borderRadius: "8px",
                          height: "40px",
                        }}
                      >
                        <img src={Answer} alt="Calling" />
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Button
                        onClick={this.decline}
                        fullWidth
                        aria-label="decline"
                        style={{
                          background: "#E52727",
                          borderRadius: "8px",
                          height: "40px",
                        }}
                      >
                        <img src={CallEnd} alt="Call" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </div>
      );
    }
  };
  renderCallerRejectSanckbar = (type, open, message) => {
    let agoraCallsData = {
      appId: "",
      channelName: "",
      agoraToken: "",
      initiaterID: "",
      typeofCall: "",
      show: false,
      receiverName: "",
    };
    let details = JSON.parse(this.props.notification.Provider);
    this.props.showCallMessage("reset", false, "");
    setTimeout(() => {
      if (this.props.onetooneCallStatus) {
        this.props.openCallWindow(agoraCallsData);
      } else if (!this.props.groupCallStatus) {
        this.props.openCallWindow(agoraCallsData);
      }
    }, 1000);
    if (type === "CallerReject") {
      toast.warn(details.name + " declined");
    }
    this.nullFirebaseDB();
    this.setState({
      agoraInitial: false,
      agoraToken: "",
      agoraChannelId: "",
      type: "",
      messageType: "",
      message: "",
      videoCallonFocus: true,
    });
  };
  renderCallDisconnected = (type, open, message) => {
    const agoraCallsData = {
      appId: "",
      channelName: "",
      agoraToken: "",
      initiaterID: "",
      typeofCall: "",
      show: false,
      receiverName: "",
    };
    if (this.props.onetooneCallStatus) {
      this.props.openCallWindow(agoraCallsData);
    } else if (!this.props.groupCallStatus) {
      this.props.openCallWindow(agoraCallsData);
    }
    if (type === "callDisconnected") {
      toast.warn("Disconnecting");
    }
    let callStatus = {
      incomingCallStatus: false,
    };
    this.props.isIncoming(callStatus);
    this.nullFirebaseDB();
  };
  renderNoAnswering = (type, open, message) => {
    let agoraCallsData = {
      appId: "",
      channelName: "",
      agoraToken: "",
      initiaterID: "",
      typeofCall: "",
      show: false,
      receiverName: "",
    };
    const details = JSON.parse(this.props.notification.Provider);
    setTimeout(() => {
      if (this.props.onetooneCallStatus) {
        this.props.openCallWindow(agoraCallsData);
      } else if (!this.props.groupCallStatus) {
        this.props.openCallWindow(agoraCallsData);
      }
    }, 0);
    toast.warn(
      details.name.length > 17
        ? details.name.slice(0, 17) + "..."
        : details.name + " did not answer"
    );
    this.nullFirebaseDB();
  };

  renderAudioCallSanckbar = (type, open, message) => {
    const { classes } = this.props;
    const details = JSON.parse(this.props.notification.Provider);
    let profilePicUrl = details["profilePicUrl"];
    let auditId = this.props.notification.auditId;
    //const splitName = details.name.split(" ");
    // const combineLetters =
    //   splitName[0].replace(/[\s'.-]/g, "")[0].toUpperCase() +
    //   splitName[1].replace(/[\s'.-]/g, "")[0].toUpperCase();
    const combineLetters =
      details.fname.replace(/[\s'.-]/g, "")[0].toUpperCase() +
      details.lname.replace(/[\s'.-]/g, "")[0].toUpperCase();
    if (this.state.open) {
      setTimeout(() => {
        if (this.state.open) {
          this.setState({ audiocall: false, open: false }, () => {
            const testToken2 = LocalStorageGetValue("token");
            const ids1 = JSON.parse(LocalStorageGetValue("id"));
            this.setState({ open: false });
            const remoteUserId = JSON.parse(this.props.notification.Provider);
            let patient;
            if (this.props.notification?.Patient) {
              patient = JSON.parse(this.props.notification?.Patient);
            }
            const url = `api/providerEndpoints/v1/sendMessage`;
            const params = {
              providerId: ids1,
              patientId: patient ? patient?.patientId : "",
              token: testToken2.replace(/"/g, ""),
              receiverId: remoteUserId.id,
              message: "No Answering",
              auditId,
              type: "No Answering",
            };
            if (this.state.notification.message.includes("-")) {
              checkingProviderStatus(url, params).then();
            } else {
              const { Provider } = this.props.notification;
              const { id: receiverId } = JSON.parse(Provider);
              groupCallNotAnswered(
                String(this.props.notification.auditId),
                String(this.props.notification.message),
                Number(this.props.notification.message),
                Number(receiverId)
              );
            }
            this.setState({
              agoraInitial: false,
              agoraToken: "",
              agoraChannelId: "",
              type: "",
              messageType: "",
              message: "",
              videoCallonFocus: true,
            });
            this.nullFirebaseDB();

            this.props.handleGroupCallFlag();
          });
        }
        let callStatus = {
          incomingCallStatus: false,
        };
        this.props.isIncoming(callStatus);
      }, 50000);
    }
    if (type === "audio_call") {
      return (
        <div className="audio_call_snackbar">
          <Snackbar
            open={this.state.open}
            style={{ zIndex: 1401 }}
            onClose={() => this.handleClosess}
            TransitionComponent={this.state.Transition}
            key={this.state.Transition.name}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            ContentProps={{
              "aria-describedby": "message-id",
              className: classes.snackbarStyleViaContentProps,
            }}
            message={
              <Grid
                container
                spacing={2}
                style={{
                  background: "#172B4D",
                  borderRadius: "15px",
                  height: "308px",
                  width: "254px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className="header-h7-light">
                    Incoming Audio Call
                  </Typography>
                  <div className="snackbarAvator">
                    <div className="snackbarSecondaryAvator">
                      <Avatar
                        alt="avator"
                        style={{
                          height: "44.77px",
                          width: "44.45px",
                          alignSelf: "center",
                          background: "white",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            color: "#8044b9",
                            fontSize: "22px",
                            fontWeight: "bold",
                          }}
                        >
                          {profilePicUrl != null ? (
                            <img
                              width="50px"
                              height="50px"
                              src={profilePicUrl}
                            />
                          ) : (
                            combineLetters
                          )}
                        </Typography>
                      </Avatar>
                    </div>
                  </div>
                  {details.remoteProviderType.includes("Bedside Clinician") && (
                    <Typography
                      style={{ marginTop: "12px" }}
                      className="content-emphasized-sidenav-text"
                    >
                      {details.name.length > 17
                        ? details.name.slice(0, 17) + "..."
                        : details.name}{" "}
                    </Typography>
                  )}
                  {details.remoteProviderType.includes("Bedside Clinician") && (
                    <Typography
                      style={{
                        marginTop: "12px",
                        textAlign: "center",
                        overflowWrap: "break-word",
                      }}
                      className="content-emphasized-sidenav-text"
                    >
                      {details.displayName ||
                        details.remoteProviderType?.replace(
                          "Provider",
                          "Clinician"
                        )}{" "}
                    </Typography>
                  )}
                  {!details.remoteProviderType.includes(
                    "Bedside Clinician"
                  ) && (
                    <Typography
                      style={{
                        marginTop: "12px",
                        textAlign: "center",
                        overflowWrap: "break-word",
                      }}
                      className="content-emphasized-sidenav-text"
                    >
                      {details.name.length > 17
                        ? details.name.slice(0, 17) + "..."
                        : details.name}
                      , {details?.displayName || details.remoteProviderType}
                    </Typography>
                  )}
                  {!details.remoteProviderType.includes(
                    "Bedside Clinician"
                  ) && (
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Remote Clinician
                    </Typography>
                  )}
                </Grid>
                <Divider style={{ border: "1px solid #5E6C84" }} />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    borderTop: "1px solid #5E6C84",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6}>
                      <Button
                        onClick={this.joinCall}
                        fullWidth
                        aria-label="accept"
                        style={{
                          background: "#27AE60",
                          borderRadius: "8px",
                          height: "40px",
                        }}
                      >
                        <img src={Answer} alt="Calling" />
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Button
                        onClick={this.decline}
                        fullWidth
                        aria-label="decline"
                        style={{
                          background: "#E52727",
                          borderRadius: "8px",
                          height: "40px",
                        }}
                      >
                        <img src={CallEnd} alt="Call" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </div>
      );
    }
  };
  render() {
    return (
      <div>
        {this.state.enableSnackBar &&
          this.state.messageType === "new_message" &&
          this.state.chat &&
          this.renderNewMessageSanckbar(
            this.state.messageType,
            true,
            this.state.message
          )}
        {this.state.enableSnackBar &&
          this.state.messageType === "video_call" &&
          this.state.videocall &&
          this.renderVideoCallSanckbar(
            this.state.messageType,
            true,
            this.state.message
          )}
        {this.state.enableSnackBar &&
          this.state.messageType === "CallerReject" &&
          this.state.chat &&
          this.renderCallerRejectSanckbar(
            this.state.messageType,
            true,
            this.state.message
          )}
        {this.state.enableSnackBar &&
          this.state.messageType === "audio_call" &&
          this.state.audiocall &&
          this.renderAudioCallSanckbar(
            this.state.messageType,
            true,
            this.state.message
          )}
        {this.state.enableSnackBar &&
          this.state.messageType === "SOS" &&
          this.state.soscall &&
          this.renderSOSCallSanckbar(
            this.state.messageType,
            true,
            this.state.message
          )}
        {this.state.enableSnackBar &&
          this.state.messageType === "callDisconnected" &&
          this.state.callDisconnected &&
          this.renderCallDisconnected(
            this.state.messageType,
            true,
            this.state.message
          )}
        {(this.state.messageType === "No Answering" ||
          this.state.messageType === "CallerNotAnswer") &&
          this.state.noAnswering &&
          this.renderNoAnswering(
            this.state.messageType,
            true,
            this.state.message
          )}
      </div>
    );
  }
}
SnackbarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    onetooneCallStatus: state.CallReducerShow.show,
    groupCallStatus: state.CallReducerShow.groupCallShow,
    remoteCallInitiatorId:
      state.CallReducerShow.agoraCallsData?.remoteCallInitiator,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    triggerSOSCAlls: (data) => dispatch(showmodel(data)),
    openCallWindow: (data) => dispatch(showmodel(data)),
    openGroupCallWindow(data) {
      dispatch(showGroupModel(data));
    },
    showCallMessage: (type, open, message) =>
      dispatch(showMessage(type, open, message)),
    isIncoming: (data) => {
      dispatch(incomingCallAction(data));
    },
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent)
);
