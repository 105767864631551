import produce from "immer";

const initialState = {
  permissionDenyModel: false,
};

export default produce(function (draft, action) {
  if (typeof draft === "undefined") {
    return initialState;
  } else if (action.type === "permissionDenyState") {
    draft.permissionDenyModel = action.payload;
  }
});
