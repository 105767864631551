import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Badge,
} from "@material-ui/core";
import settingsIcon from "../../Assets/images/settingsIcon.png";
import playIcon from "../../Assets/images/PlayIcon.png";
import NotificationIcon from "../../Assets/images/Icons dashboard/Notification.png";
import NotificationIconCircle from "../../Assets/images/notification-4-circle.gif";
import Circle1 from "../../Assets/images/Subtract (1).svg";
import Circle2 from "../../Assets/images/Subtract.svg";
import Circle3 from "../../Assets/images/Subtract (2).svg";
import UserProfile from "../Userprofile/Userprofile";
import "./MainLayout.scss";
import "firebase/auth";
import "firebase/storage";
import {
  LocalStorageGetValue,
  LocalStorageSetValue,
} from "../../Services/Auth";
import NotificationBar from "../Notification/notificationComponent";
import Popper from "@mui/material/Popper";
import { makeStyles } from "@material-ui/core/styles";
import {
  GetNotificationService,
  GetSeverTime,
} from "../../Services/Notification";
import Firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";
import { ToggleOnlineConference } from "../../Redux/Actions/checkZoomDetails";

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 2,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  notificationCount: {
    color: "white",
    position: "relative",
    fontWeight: 600,
  },
}));

export default function Header(props) {
  const patientDetails = useSelector((state) => state.patientDetailsReducer);
  const classes = useStyles();
  const dispatch = useDispatch();
  const notificationRef = useRef(patientDetails);
  const [loader, setloader] = React.useState(false);

  const isMenuOpen = Boolean(props.anchorEl);
  const [anchorProvider, setanchorProvider] = useState(null);
  const [profileImage, setImage] = React.useState(null);
  const [serverTime, setserverTime] = useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifyStatus, setnotifyStatus] = React.useState("open");
  const notifyStatusRef = useRef(notifyStatus);
  const [notifyData, setnotifyData] = React.useState([]);
  const [notificationCount, setnotificationCount] = React.useState(0);
  const [notificationCountFontSize, setnotificationCountFontSize] =
    useState(10);
  const [notificationVibration, setnotificationVibration] =
    React.useState(false);

  const open = Boolean(anchorEl);

  const handleProfileMenuOpen = async (event) => {
    setloader(true);
    const userDetails = JSON.parse(LocalStorageGetValue("user"));
    Firebase.database()
      .ref("providers/" + userDetails.uid)
      .update({ notificationCount: 0 }, (err) => {
        console.log("notification count update error:", err);
      });
    dispatch({ type: "NOTIFICATIONCOUNT", payload: { notificationCount: 0 } });
    setnotificationVibration(false);
    if (notifyStatus === "open") {
      setAnchorEl(event.currentTarget);
      setnotifyStatus("close");
    } else {
      setAnchorEl(null);
      setnotifyStatus("open");
    }
    let finalNotificationList = [];
    const resnotifyData = await GetNotificationService();
    const serverTimeRes = await GetSeverTime();
    setserverTime(serverTimeRes.currentTime);

    if (resnotifyData.status) {
      setloader(false);
      if (resnotifyData.notificationListResponseList.length > 0) {
        let i = 0,
          len = resnotifyData.notificationListResponseList.length;
        while (i < len) {
          if (finalNotificationList.length < 7) {
            resnotifyData.notificationListResponseList[i].notificationList.map(
              (item) => {
                finalNotificationList.push(item);
              }
            );
          } else {
            break;
          }
          i++;
        }
      }
    }
    setnotifyData(finalNotificationList);
    setnotificationCount(notificationRef.current.notificationCount);
  };

  const fName = props.userInfo?.fname.replace(/[\s'.-]/g, "").charAt(0);
  const lName = props.userInfo?.lname.replace(/[\s'.-]/g, "").charAt(0);
  const openUserSettings = (event) => {
    LocalStorageSetValue("changePasswordExapand", false);
    props.history.push("/settings");
    window.history.replaceState(null, "", "/");
    sessionStorage.setItem("currentPath", "/settings");
  };

  const openTutorials = (event) => {
    let tutorialURL = JSON.parse(
      LocalStorageGetValue("userDetails")
    ).tutorial_url;
    window.open(tutorialURL, "_blank");
  };

  const profileDetails = (event) => {
    if (anchorProvider === null) {
      setanchorProvider(event.currentTarget);
    } else setanchorProvider(null);
  };

  useEffect(() => {
    notificationRef.current = patientDetails;
  }, [patientDetails]);

  useEffect(() => {
    notifyStatusRef.current = notifyStatus;
  }, [notifyStatus]);

  useEffect(() => {
    if (notificationCount) {
      let count = notificationCount.toString().length;
      if (count === 1) {
        setnotificationCountFontSize(10);
      } else if (count === 2) {
        setnotificationCountFontSize(9);
      } else if (count === 3) {
        setnotificationCountFontSize(8);
      } else if (count === 4) {
        setnotificationCountFontSize(7);
      }
    }
  }, [notificationCount]);
  useEffect(() => {
    const userDetails = JSON.parse(LocalStorageGetValue("user"));
    let notificationCountRef = Firebase.database().ref(
      "providers/" + userDetails.uid + "/notificationCount"
    );
    notificationCountRef.on("value", async function (snapshot) {
      const count = snapshot.val();
      if (notifyStatusRef.current === "open") {
        if (count > 0) {
          setnotificationVibration(true);
        } else {
          setnotificationVibration(false);
        }
        dispatch({
          type: "NOTIFICATIONCOUNT",
          payload: { notificationCount: count },
        });
        setnotificationCount(count);
      }
    });
  }, []);

  useEffect(() => {
    const userDetails = JSON.parse(LocalStorageGetValue("user"));
    let starCountRef = Firebase.database().ref(
      "providers/" + userDetails.uid + "/notification"
    );
    starCountRef.on("value", async function (snapshot) {
      const DBMessages = snapshot.val();
      if (DBMessages?.messageType === "ZoomConference") {
        dispatch(ToggleOnlineConference(true));
      }
      if (snapshot.val() !== null) {
        if (notifyStatusRef.current === "close") {
          setloader(true);
          let finalNotificationList = [];
          const notifyDataResponse = await GetNotificationService();
          const serverTimeRes = await GetSeverTime();
          setserverTime(serverTimeRes.currentTime);

          if (notifyDataResponse.status) {
            setloader(false);
            if (notifyDataResponse.notificationListResponseList.length > 0) {
              let i = 0,
                len = notifyDataResponse.notificationListResponseList.length;
              while (i < len) {
                if (finalNotificationList.length < 7) {
                  notifyDataResponse.notificationListResponseList[
                    i
                  ].notificationList.map((item) => {
                    finalNotificationList.push(item);
                  });
                } else {
                  break;
                }
                i++;
              }
            }
          }
          setnotifyData(finalNotificationList);
        }
        if (
          DBMessages?.messageType &&
          [
            "new_message",
            "Logout",
            "CallerReject",
            "audio_call",
            "video_call",
            "SOS",
            "sos_dismiss",
            "callDisconnected",
            "No Answering",
            "CallerNotAnswer",
          ].includes(DBMessages?.messageType) === false
        ) {
          const dbRef1 = Firebase.database().ref("providers/");
          dbRef1.child(userDetails.uid).child("notification").set(null);
        }
      }
    });
  }, []);
  useEffect(() => {
    const user = JSON.parse(LocalStorageGetValue("userDetails"));
    setImage(user?.profilePicUrl);
  }, []);
  useEffect(() => {
    setloader(true);
    const getData = async () => {
      let finalNotificationList = [];
      const notifyDataRes = await GetNotificationService();
      const serverTimeRes = await GetSeverTime();
      setserverTime(serverTimeRes.currentTime);

      if (notifyDataRes?.status) {
        setloader(false);
        if (notifyDataRes.notificationListResponseList.length > 0) {
          let i = 0,
            len = notifyDataRes.notificationListResponseList.length;
          while (i < len) {
            if (finalNotificationList.length < 7) {
              notifyDataRes.notificationListResponseList[
                i
              ].notificationList.map((item) => {
                finalNotificationList.push(item);
              });
            } else {
              break;
            }
            i++;
          }
        }
      }
      setnotifyData(finalNotificationList);
      setnotificationCount(notificationRef.current.notificationCount);
    };
    getData();
  }, []);

  return (
    <div>
      <div>
        <Toolbar style={{ padding: 0, minHeight: 0 }}>
          <IconButton
            aria-owns={isMenuOpen ? "material-appbar" : undefined}
            aria-haspopup="false"
            style={{ padding: "12px" }}
            onClick={openTutorials}
          >
            <img alt="Play" src={playIcon} />
          </IconButton>
          <IconButton
            aria-owns={isMenuOpen ? "material-appbar" : undefined}
            aria-haspopup="false"
            style={{ padding: "12px" }}
            onClick={openUserSettings}
          >
            <img alt="settingsIcon" src={settingsIcon} />
          </IconButton>
          <IconButton
            aria-owns={isMenuOpen ? "material-appbar" : undefined}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <div style={{ position: "absolute" }}>
              {notificationVibration ? (
                <div
                  style={{
                    width: 69,
                    height: 69,
                    position: "relative",
                    transform: "scale(0.666)",
                  }}
                  className="notificationBlink"
                >
                  <img src={Circle1} />
                  <img src={Circle2} />
                  <img src={Circle3} />
                </div>
              ) : // <img
              //   alt="NotificationIconCircle"
              //   src={NotificationIconCircle}
              //   style={{ width: 50 }}
              // />
              null}
            </div>
            <div style={{ position: "relative" }}>
              <Badge color="error" style={{ padding: "12px" }}>
                <img alt="NotificationIcon" src={NotificationIcon} />
              </Badge>
              {notificationVibration ? (
                <span className="notification-dot">
                  <span
                    style={{ fontSize: notificationCountFontSize }}
                    className={classes.notificationCount}
                  >
                    {notificationCount}
                  </span>
                </span>
              ) : null}
            </div>
          </IconButton>
          <Popper
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, 20],
                },
              },
              {
                name: "arrow",
                enabled: true,
              },
            ]}
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 2 }}
          >
            <NotificationBar
              setAnchorEl={setAnchorEl}
              setnotifyStatus={setnotifyStatus}
              data={notifyData}
              props={props}
              loader={loader}
              serverTime={serverTime}
            />
          </Popper>

          <Button
            onClick={(event) => profileDetails(event)}
            style={{
              textTransform: "inherit",
              textAlign: "start",
              borderRadius: "28px",
              border: "1px solid #EBECF0",
            }}
          >
            {profileImage !== null ? (
              <img
                src={profileImage}
                className="headerprofileImage"
                alt="profileImage"
              />
            ) : (
              <Avatar className="headerprofileImage" alt="ProfilePicture">
                {fName}
                {lName}
              </Avatar>
            )}
            <div className="online-dot"></div>
            <div style={{ paddingLeft: "16px" }}>
              <Typography className="content-emphasized-text name">
                {props.providerName && props.providerName.length > 27
                  ? props.providerName.slice(0, 27) + "..."
                  : props.providerName}
              </Typography>
              <Typography className="provider-type">
                {props.userInfo &&
                  props.userInfo.role === "BD" &&
                  (props.userInfo?.displayName || "Bedside Clinician")}
                {props.userInfo &&
                  props.userInfo.role === "RD" &&
                  "Remote Clinician "}
                {props?.userInfo?.role === "RD" &&
                  `(${
                    props.userInfo?.displayName ||
                    props.userInfo?.remoteProviderType
                  })`}
              </Typography>
            </div>
          </Button>
          <div style={{ width: 30 }}></div>
        </Toolbar>
      </div>
      <UserProfile
        anchorProvider={anchorProvider}
        setanchorProvider={setanchorProvider}
        history={props.history}
        fName={fName}
        lName={lName}
        userInfo={props.userInfo}
        profileImage={profileImage}
        setImage={setImage}
        logout={() => props.logout()}
      />
    </div>
  );
}
