export function leftPanelExpanded() {
  return {
    type: "leftPanelExpanded",
  };
}
export function leftPanelCollapsed() {
  return {
    type: "leftPanelcollapsed",
  };
}
