import produce from "immer";

const initialState = {
  selectedMyActivePatient: null,
  selectedAcuity: "All",
  selectedWard: null,
  selectedHospitals: null,
  selectedPatients: null,
  wardList: null,
  isPrePatientCensus: false,
  patientList: [],
};

export default produce(function (draft, action) {
  if (typeof draft === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case "patientList": {
      draft.patientList = action.payload;
      return draft;
    }
    case "isPrePatientCensus": {
      draft.isPrePatientCensus = action.payload;
      return draft;
    }
    case "selectedMyActivePatient": {
      draft.selectedMyActivePatient = action.payload;
      return draft;
    }
    case "selectedAcuity": {
      draft.selectedAcuity = action.payload;
      return draft;
    }
    case "selectedWard": {
      draft.selectedWard = action.payload;
      return draft;
    }
    case "selectedHospitals": {
      draft.selectedHospitals = action.payload;
      return draft;
    }
    case "selectedPatients": {
      draft.selectedPatients = action.payload;
      return draft;
    }
    case "wardList": {
      draft.wardList = action.payload;
      return draft;
    }
    default:
      return draft;
  }
});
