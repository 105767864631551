import produce from "immer";

const initialState = {
  room: null,
  chatLog: [],
  username: null,
  messages: [],
  columnValue: {
    item1: 6,
    item2: 6,
  },
};

export default produce((draft, action) => {
  if (typeof draft === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case "messages":
      draft.messages = action.payload;
      break;
    case "columnview":
      draft.columnValue = action.payload;
      break;
    default:
      break;
  }
}, initialState);
