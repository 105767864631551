import { React, useState } from "react";
import "../../Styles/Typography/CustomTypography.scss";
import { Typography, Link } from "@material-ui/core";
import { parse } from "node-html-parser";
import htmlparser from "html-react-parser";
import TruncateMarkup from "react-truncate-markup";

function TruncatehtmlString({ textDetails, sideHeading }) {
  //const truncateRef = useRef();
  // const [containerHeight, setContainerHeight] = useState(30);
  // const [moreCondition, setShowMoreCondition] = useState(false);
  // const [collapsibleCondition, setCollapsibleCondition] = useState(false);
  const [textTruncate, setTextTruncate] = useState(true);

  // function changeContainerHeight() {
  //   if (containerHeight === 30) {
  //     setContainerHeight("max-content");
  //     setShowMoreCondition(false);
  //   } else if (
  //     typeof containerHeight === "string" &&
  //     String(containerHeight) === "max-content"
  //   ) {
  //     setContainerHeight(30);
  //     setShowMoreCondition(true);
  //   }
  // }
  const link = {
    display: "inline-block",
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  };
  // useLayoutEffect(() => {
  //   if (
  //     truncateRef.current.offsetHeight <
  //     truncateRef.current.querySelector("div").offsetHeight
  //   ) {
  //     setCollapsibleCondition(true);
  //     setShowMoreCondition(true);
  //   }
  // }, []);
  const readMoreEllipsis = (
    <span>
      ...{" "}
      <span
        onClick={() => {
          setTextTruncate(false);
        }}
        style={link}
      >
        more
      </span>
    </span>
  );

  return (
    <>
      {textTruncate ? (
        <TruncateMarkup lines={2} ellipsis={readMoreEllipsis}>
          <div style={{ wordBreak: "break-all" }}>
            {sideHeading}
            {htmlparser(textDetails)}
          </div>
        </TruncateMarkup>
      ) : (
        <div style={{ wordBreak: "break-all" }}>
          {sideHeading}
          {htmlparser(textDetails)}
          &nbsp;
          <span
            onClick={() => {
              setTextTruncate(true);
            }}
            style={link}
          >
            {"less"}
          </span>
        </div>
      )}
    </>
  );
  // return (
  //   <div
  //     style={{ height: containerHeight, overflow: "hidden", width: "80%" }}
  //     ref={truncateRef}
  //   >
  //     {textDetails}
  //     {collapsibleCondition && (
  //       <Link
  //         style={{ position: "absolute", bottom: 0, right: 5 }}
  //         onClick={changeContainerHeight}
  //       >
  //         {moreCondition ? "more" : "less"}
  //       </Link>
  //     )}
  //   </div>
  // );
}

function LongText({ data, classes, providerNameHighlight }) {
  const [moreLess, setmoreLess] = useState(true);
  function showMoreLess() {
    setmoreLess(!moreLess);
  }
  function addNotificationTitle(message, type, classesChild, title) {
    if (type === "System Alert") {
      return <span className={classesChild.subText}>System Alert -</span>;
    } else if (type === "new_message") {
      return <span className={classesChild.subText}>New Message -</span>;
    } else if (
      type === "InvitationAccept" ||
      type === "Invitation" ||
      type === "Discharged"
    ) {
      if (message.includes("Handoff accepted")) {
        return <span className={classesChild.subText}>Handoff Accepted -</span>;
      } else {
        return <span className={classesChild.subText}>{`${title} -`}</span>;
      }
    } else if (type === "Acuity Change Alert") {
      return (
        <span className={classesChild.subText}>Acuity Change Alert -</span>
      );
    } else if (type === "New Hospital Enabled") {
      return (
        <span className={classesChild.subText}>New Hospital Enabled -</span>
      );
    } else if (type === "Password Expiry") {
      return <span className={classesChild.subText}>Password Expiry -</span>;
    } else if (type === "Session Expiry Warning") {
      return (
        <span className={classesChild.subText}>Session Expiry Warning -</span>
      );
    } else if (type === "Handoff Initiated") {
      return <span className={classesChild.subText}>{`${title} -`}</span>;
    } else if (type === "Patient Assigned") {
      return <span className={classesChild.subText}>Patient Assigned -</span>;
    } else if (type === "Patient transferred") {
      return (
        <span className={classesChild.subText}>Patient transferred -</span>
      );
    } else if (type === "Patient Vitals Change Alert") {
      return <span className={classesChild.subText}>{`${title} -`}</span>;
    } else if (type === "ZoomConference") {
      return <span className={classesChild.subText}>{`${title} -`}</span>;
    } else {
      return null;
    }
  }

  function returnMoreless(dispType) {
    if (dispType === "more") {
      return (
        <span>
          ...
          <Link onClick={() => showMoreLess()}> more</Link>
        </span>
      );
    } else if (dispType === "less") {
      return (
        <span>
          <Link onClick={() => showMoreLess()}> less</Link>
        </span>
      );
    } else {
      return null;
    }
  }
  function addLink(
    text,
    type,
    senderName,
    dispType,
    providerNameHighlightParent,
    classesSubchild
  ) {
    if (dispType === "more") {
      text = text.substring(0, 90);
    }
    let messsageNotification = text.split(senderName);
    if (messsageNotification.length === 1) {
      return (
        <span>
          {messsageNotification[0]}
          {returnMoreless(dispType)}
        </span>
      );
    } else {
      return (
        <span>
          {messsageNotification[0]}
          {providerNameHighlightParent === "color" ? (
            <span
              className={classesSubchild.subText}
              style={{ color: "var(--primary-color)" }}
            >
              {senderName}
            </span>
          ) : (
            <span className={classesSubchild.subText}>{senderName}</span>
          )}
          {messsageNotification[1]}
          {returnMoreless(dispType)}
        </span>
      );
    }
  }

  function getModifiedText(text, senderName, type, classesParent, title) {
    const checkforhtml = parse(text);
    if (
      checkforhtml.getElementsByTagName("p").length ||
      checkforhtml.getElementsByTagName("a").length
    ) {
      checkforhtml.querySelector("p").setAttribute("style", "display: inline");
      return (
        <TruncatehtmlString
          sideHeading={
            <>{addNotificationTitle(text, type, classesParent, title)}&nbsp;</>
          }
          textDetails={checkforhtml.toString()}
          /*textDetails={
            <>
              {addNotificationTitle(text, type, classesParent)}&nbsp;
              <div
                style={{
                  display:
                    checkforhtml.getElementsByTagName("p").length > 1
                      ? "inline-block"
                      : "inline",
                }}
                dangerouslySetInnerHTML={{ __html: checkforhtml.toString() }}
              ></div>
            </>
          }*/
        />
      );
    }
    if (text.length > 90) {
      return (
        <div>
          {moreLess ? (
            <div>
              {addNotificationTitle(text, type, classesParent, title)}&nbsp;
              {addLink(
                text,
                type,
                senderName,
                "more",
                providerNameHighlight,
                classes
              )}
            </div>
          ) : (
            <div>
              {addNotificationTitle(text, type, classesParent, title)}&nbsp;
              {addLink(
                text,
                type,
                senderName,
                "less",
                providerNameHighlight,
                classes
              )}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {addNotificationTitle(text, type, classesParent, title)}&nbsp;
          {addLink(
            text,
            type,
            senderName,
            "normal",
            providerNameHighlight,
            classes
          )}
        </div>
      );
    }
  }
  return (
    <div>
      <Typography className="notification-content-text">
        {getModifiedText(
          data.message?.replaceAll("Provider", "Clinician"),
          data.senderName,
          data.messageType,
          classes,
          data.title
        )}
      </Typography>
    </div>
  );
}

export default LongText;
