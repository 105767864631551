import React, { useEffect, useState, useMemo, useCallback } from "react";
import { createPortal } from "react-dom";
import Draggable from "react-draggable";
import { getDevices } from "agora-rtc-sdk-ng";
//import { getDevices, getCameras, getMicrophones } from "agora-rtc-sdk-ng";
import {
  showminimum,
  showMessage,
  showGroupCallminimum,
  showGroupCallmaximum,
  showGroupModel,
  showmodel,
} from "../../Redux/Actions/callActions";
import { useSelector, useDispatch } from "react-redux";
import { setPermissionPopup } from "../../Redux/Actions/permissionPopupStateAction";
import "./MainLayout.scss";
import "./callerWindow.scss";
import AgoraCall from "../AgoraCalls/AgoraCall";
import SOSAgoraCall from "../AgoraCalls/SosCalls";
import AgoraGroupCall from "../AgoraCalls/AgoraGroupCall";
import { collapse } from "./collapseWindow";
import { checkingProviderStatus } from "../../Services/AudVidCalls/AudVidCalls";
import {
  groupCallDisconnect,
  callDisconnectsaveCallAudit,
  callConnectsaveCallAudit,
  groupCallNotAnswered,
} from "../../Services/GroupCall/GroupCall";
import { LocalStorageGetValue } from "../../Services/Auth";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { throttle } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const usePermissionDenyStyles = makeStyles({
  dialogTitleWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  dialogTitle: { fontFamily: "Poppins", fontWeight: 700, fontSize: "1.125rem" },
  dialogPaper: { width: 600, borderRadius: 10 },
  closeIcon: { padding: 12 },
  mainText: {
    fontSize: "1.125rem",
    lineHeight: 1.5,
    fontWeight: 600,
    textAlign: "center",
  },
  subText: { fontSize: "0.75rem", lineHeight: 1.5, textAlign: "center" },
  dialogContentWrapper: {
    height: 196,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  buttonStyles: {
    paddingLeft: 20,
    paddingRight: 20,
    border: "2px solid var(--primary-color)",
    borderRadius: 8,
    color: "var(--primary-color)",
    transform: "translateX(-15px)",
    fontWeight: 600,
    height: 40,
    "&:hover": {
      border: "2px solid var(--primary-color)",
    },
  },
});

const useCheckCamMicPermission = (callInitiate, permissionDenyModelState) => {
  const [permissionStatus, setPermissionStatus] = useState("");
  useEffect(() => {
    if (callInitiate) {
      //       navigator.mediaDevices.getUserMedia({audio:true,video:true})
      // .then(function(stream) {
      //   console.log(stream,'--------------------------------Navigator-----------------------')
      // })
      // .catch(function(err) {
      //   console.log(err,'----------------------------------navigator_error-------------------------')
      // });
      //navigator.mediaDevices.enumerateDevices().then(devices=>console.log('-------------------------Navigator_Devices--------------------------',devices)).catch(error=>console.log('----------------------------Navigator_Enumerate-Error-------------------',error))
      getDevices()
        .then(() => {
          setPermissionStatus("Active");
        })
        .catch((error) => {
          if (error.code === "PERMISSION_DENIED") {
            setPermissionStatus("Denied");
          }
        });
      // getCameras().then(data=>{
      //   console.log(data,"------------------------CamerasDatas-----------------------")
      // }).catch(error=>{
      //   console.log(error,"ERRORS------------------Cameras")
      // })
      // getMicrophones().then(data=>{
      //   console.log(data,'----------------------MicrophoneDatas------------------------')
      // }).catch(error=>{
      //   console.log(error,"Errors------------------Microphones")
      // })
    } else {
      //setPermissionStatus('')
    }
  }, [callInitiate]);
  useEffect(() => {
    if (!permissionDenyModelState) {
      setPermissionStatus("");
    }
  }, [permissionDenyModelState]);
  return permissionStatus;
};

const callDOM = React.createRef();
// eslint-disable-next-line
let minimumEnabled = false;
const CallerWindow = () => {
  const show = useSelector((state) => state);
  const showCallerWindow = useSelector((state) => state);
  const leftPanelMenu = useSelector(
    (state) => state.LeftMenuPanel.leftPanelExpanded
  );
  const portraitMode = useMediaQuery("(orientation:portrait)");
  const permissionDenyModel = useSelector(
    ({ permissionPopupReducer: { permissionDenyModel } }) => permissionDenyModel
  );
  const agoraCallsData = useSelector(
    ({ CallReducerShow: { agoraCallsData } }) => agoraCallsData
  );
  const { auditId, channelName } = agoraCallsData;
  const providerStatus = useSelector((state) => state.providerStatus);
  const dispatch = useDispatch();

  const [callPositionX, setCallPositionX] = useState(0);

  const [callPositionY, setCallPositionY] = useState(0);

  const callPosition = useMemo(
    () => ({ x: callPositionX, y: callPositionY }),
    [callPositionX, callPositionY]
  );

  const setCallPosition = ({ x, y }) => {
    setCallPositionX(x);
    setCallPositionY(y);
  };

  const [groupCallPositionX, setGroupCallPositionX] = useState(0);

  const [groupCallPositionY, setGroupCallPositionY] = useState(0);

  const groupCallPosition = useMemo(
    () => ({ x: groupCallPositionX, y: groupCallPositionY }),
    [groupCallPositionX, groupCallPositionY]
  );

  const setGroupCallPosition = ({ x, y }) => {
    setGroupCallPositionX(x);
    setGroupCallPositionY(y);
  };

  const onetooneCallPermissionStatus = useCheckCamMicPermission(
    showCallerWindow.CallReducerShow.show,
    permissionDenyModel
  );
  const groupCallPermissionStatus = useCheckCamMicPermission(
    showCallerWindow.CallReducerShow.groupCallShow,
    permissionDenyModel
  );
  const token = LocalStorageGetValue("token");

  useEffect(() => {
    if (
      onetooneCallPermissionStatus === "Denied" ||
      groupCallPermissionStatus === "Denied"
    ) {
      dispatch(setPermissionPopup(true));
      // setPermissionDenyModel(true)
      if (
        onetooneCallPermissionStatus === "Denied" &&
        !showCallerWindow.CallReducerShow.agoraCallsData.sosCalls
      ) {
        const { auditId, channelName, initiaterID, receiverId } =
          agoraCallsData;
        if (auditId && channelName && initiaterID && receiverId) {
          checkingProviderStatus(`api/providerEndpoints/v1/sendMessage`, {
            auditId,
            patientId: agoraCallsData?.patientId
              ? agoraCallsData?.patientId
              : "",
            token: token.replace(/"/g, ""),
            receiverId,
            providerId: initiaterID,
            type: "CallerReject",
            message: "CallerReject",
          }).then(() => {
            let agoraCallsData = {
              appId: "",
              channelName: "",
              agoraToken: "",
              initiaterID: "",
              typeofCall: "",
              show: false,
              receiverName: "",
              collapse: false,
            };
            dispatch(showmodel(agoraCallsData));
          });
        }
      } else if (groupCallPermissionStatus === "Denied") {
        if (auditId && channelName) {
          groupCallDisconnect(
            String(auditId),
            String(channelName),
            Number(channelName)
          ).then(() => {
            let agoraCallsData = {
              appId: "",
              channelName: "",
              agoraToken: "",
              initiaterID: "",
              typeofCall: "",
              show: false,
              receiverName: "",
              collapse: false,
            };
            dispatch(showGroupModel(agoraCallsData));
          });
        }
      }
    } else {
      dispatch(setPermissionPopup(false));
      //setPermissionDenyModel(false)
    }
  }, [onetooneCallPermissionStatus, groupCallPermissionStatus, agoraCallsData]);
  useEffect(() => {
    if (
      leftPanelMenu &&
      show.CallMinMax.showMaxi &&
      document.getElementsByClassName("CallStyleDOM").length > 0
    ) {
      document.getElementsByClassName("CallStyleDOM")[0].style.right = "10%";
    }
    if (
      !leftPanelMenu &&
      show.CallMinMax.showMaxi &&
      document.getElementsByClassName("CallStyleDOM").length > 0
    ) {
      document.getElementsByClassName("CallStyleDOM")[0].style.right = "19%";
      document.getElementsByClassName("CallStyleDOM")[0].style.transition =
        "left 1s";
    }
  });
  useEffect(() => {
    if (!showCallerWindow.CallReducerShow.show) {
      setCallPosition({ x: 0, y: 0 });
    }
    if (!showCallerWindow.CallReducerShow.groupCallShow) {
      setGroupCallPosition({ x: 0, y: 0 });
    }
  }, [
    showCallerWindow.CallReducerShow.show,
    showCallerWindow.CallReducerShow.groupCallShow,
  ]);
  const eventHandler = useCallback(
    throttle((e, data) => {
      if (data) {
        const { x, y } = data;
        if (showCallerWindow.CallReducerShow.show) {
          setCallPosition({ x, y });
        } else if (showCallerWindow.CallReducerShow.groupCallShow) {
          setGroupCallPosition({ x, y });
        }
      }
    }, 16),
    [
      showCallerWindow.CallReducerShow.show,
      showCallerWindow.CallReducerShow.groupCallShow,
    ]
  );

  useEffect(() => {
    if (showCallerWindow.CallReducerShow.groupCallShow) {
      if (callDOM?.current) {
        callDOM.current.props.children.style.transform = "translate(-50%,-50%)";
      }
    }
  }, []);
  if (
    showCallerWindow.CallReducerShow.show &&
    onetooneCallPermissionStatus === "Active"
  ) {
    return (
      <>
        {createPortal(
          <Draggable
            ref={callDOM}
            onMouseDown={eventHandler}
            onStart={eventHandler}
            onDrag={eventHandler}
            onStop={eventHandler}
            position={callPosition}
          >
            <div
              className="CallStyleDOM"
              style={{
                position: "fixed",
                zIndex: 1400,
                top: "11%",
                right: "19%",
                transform: "translate(-50%,-50%)",
                background: "#ffffff",
              }}
            >
              <Modal
                windowSize={show.CallMinMax.showMaxi}
                providerStatus={providerStatus}
                details={showCallerWindow}
                callerDetails={showCallerWindow.CallReducerShow.agoraCallsData}
                open={showCallerWindow.CallReducerShow.show}
              />
            </div>
          </Draggable>,
          document.getElementById("modal")
        )}
      </>
    );
  } else if (
    showCallerWindow.CallReducerShow.groupCallShow &&
    groupCallPermissionStatus === "Active"
  ) {
    return (
      <>
        {createPortal(
          <Draggable
            ref={callDOM}
            onMouseDown={eventHandler}
            onStart={eventHandler}
            onDrag={eventHandler}
            onStop={eventHandler}
            position={groupCallPosition}
          >
            <div
              className="GroupCallStyleDOM"
              style={{
                position: "fixed",
                zIndex: 1400,
                //...(maximizedWindow && {
                top: portraitMode
                  ? `calc(50% - 26vh - 12px)`
                  : `calc(50% - 19vw - 12px)`,
                right: portraitMode ? `calc(50% - 46vw)` : `calc(50% - 35vw)`,
                //}),
                //...(minimizedWindow && { top: 16, right: 16 }),
                background: "transparent",
              }}
            >
              <GroupCallModal
                windowSize={show.CallMinMax.showMaxi}
                providerStatus={providerStatus}
                details={showCallerWindow}
                callerDetails={showCallerWindow.CallReducerShow.agoraCallsData}
                open={showCallerWindow.CallReducerShow.show}
              />
            </div>
          </Draggable>,
          document.getElementById("modal")
        )}
      </>
    );
  }
  //   else if(permissionDenyModel&&false) {
  //     return <Dialog open={permissionDenyModel} PaperProps={{className:dialogPaper}}>
  //               <div className={dialogTitleWrapper}><DialogTitle id="customized-dialog-title" onClose={closePermissionDenyModel}>
  //          <div className={dialogTitle}>Call Settings</div>
  //         </DialogTitle>
  //         <IconButton aria-label="close"  onClick={closePermissionDenyModel} className={closeIcon}>
  //           <CloseIcon />
  //         </IconButton>
  //         </div>
  //         <DialogContent dividers style={{borderBottom:'inherit'}}>
  //           <div className={dialogContentWrapper}>
  //             <div><img src={CamOff} /><img src={MicOff} /></div>
  //             <div className={mainText}>You haven’t allowed Omnicure Now Access to your camera and microphone</div>
  //             <div className={subText}>Allow Omnicure Now to use your camera and microphones so others on the call can see and hear you. You can turn it off later.</div>
  //           </div>
  //           </DialogContent>
  //           <DialogActions>
  // <Button color="primary" variant='outlined' className={buttonStyles} onClick={closePermissionDenyModel}>Close</Button>
  //           </DialogActions>
  //     </Dialog>;
  //   }
  return null;
};
const Modal = ({
  open,
  callerDetails,
  details,
  providerStatus,
  windowSize,
}) => {
  const dispatch = useDispatch();
  const [callOnce, setCallOnce] = useState(true);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(showMessage("", false, ""));
    if (callOnce === true) {
      $(document).ready(function () {
        $("#div_error").focus();
      });
      setCallOnce(false);
    }
    setCallOnce(false);
  }, []);
  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    if (windowSize) {
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) {
          onBlurs();
        }
      }, 1000);
    }
  };
  const onBlurs = () => {
    dispatch(showminimum());
    minimumEnabled = true;
    if (document.getElementsByClassName("callerWindowContainer").length > 0) {
      collapse();
    }
    if (
      details.CallReducerShow.agoraCallsData.typeofCall === "video_call" &&
      !windowSize
    ) {
      setTimeout(() => {
        if (document.getElementsByClassName("timerAudio").length > 0) {
          document.getElementsByClassName("timerAudio")[0].style.top = "24%";
          document.getElementsByClassName("timerAudio")[0].style.left = "6%";
          document.getElementsByClassName("timeRun")[0].style.fontSize = "12px";
          document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
        }
      }, 3000);
    }
  };
  if (!open) return null;
  else
    return (
      <div tabIndex={0} onTouchCancel={handleBlur} onBlur={handleBlur}>
        <div className="callerWindowContainer">
          <div className="callerWindowDesign">
            <div className="callerWindowHeader">
              <div className="headerName" id="div_error" tabIndex={-1}>
                <p>NETCCN One</p>
              </div>
            </div>
            <div className="bodyContent">
              <div className="bodyContentBG">
                {callerDetails.appId !== "" ? (
                  callerDetails.sosCalls ? (
                    <SOSAgoraCall
                      appId={callerDetails.appId}
                      channelId={callerDetails.channelName}
                      token={callerDetails.agoraToken}
                      UID={callerDetails.initiaterID}
                      typeofCall={callerDetails.typeofCall}
                      auditId={callerDetails.auditId}
                      role={callerDetails.role}
                      hospital={callerDetails.hospital}
                    />
                  ) : (
                    <AgoraCall
                      patientId={callerDetails?.patientId}
                      appId={callerDetails.appId}
                      channelId={callerDetails.channelName}
                      token={callerDetails.agoraToken}
                      UID={callerDetails.initiaterID}
                      typeofCall={callerDetails.typeofCall}
                      reciverId={callerDetails.receiverId}
                      auditId={callerDetails.auditId}
                      twoLetters={callerDetails.twoLetters}
                      profilePicUrl={callerDetails.profilePicUrl}
                      role={callerDetails.role}
                      hospital={callerDetails.hospital}
                      name={callerDetails.name}
                    />
                  )
                ) : providerStatus.inActive ? (
                  <div className="ProviderStatus">{providerStatus.errMsg}</div>
                ) : (
                  <div class="lds-spinner1">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

const GroupCallModal = ({
  open,
  callerDetails,
  details,
  providerStatus,
  windowSize,
}) => {
  const { auditId, channelName } = useSelector(
    ({ CallReducerShow: { agoraCallsData } }) => agoraCallsData
  );

  const dispatch = useDispatch();

  const portraitMode = useMediaQuery("(orientation:portrait)");

  const [maximizedWindow, minimizedWindow] = useSelector(
    ({ CallMinMax: { showGroupCallMini, showGroupCallMaxi } }) => [
      showGroupCallMaxi,
      showGroupCallMini,
    ]
  );

  useEffect(() => {
    callConnectsaveCallAudit(String(auditId));
  }, []);

  const disconnectCallHandler = async () => {
    dispatch(showGroupCallmaximum());
    await groupCallDisconnect(
      String(auditId),
      String(channelName),
      Number(channelName)
    );
    await callDisconnectsaveCallAudit(String(auditId));
  };

  const callNotAnsweredHandler = async () => {
    await groupCallNotAnswered(
      String(auditId),
      String(channelName),
      Number(channelName)
    );
    await callDisconnectsaveCallAudit(String(auditId));
  };

  const handleClickAway = () => {
    dispatch(showGroupCallminimum());
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div tabIndex={0} /*onTouchCancel={handleBlur} onBlur={handleBlur}*/>
        <div
          className="groupcallerWindowContainer"
          style={{
            ...(maximizedWindow && {
              width: portraitMode ? "92vw" : "70vw",
              height: "auto",
            }),
            ...(minimizedWindow && { width: 431, height: 281 }),
          }}
        >
          <div
            className="groupcallerWindowDesign"
            style={{
              ...(maximizedWindow && {
                gridTemplateRows: `24px ${portraitMode ? "51vh" : "38vw"}`,
              }),
              ...(minimizedWindow && { gridTemplateRows: "24px 257px" }),
            }}
          >
            <div className="groupcallerWindowHeader">
              <div className="headerName" id="div_error" tabIndex={-1}>
                <p>Omnicure</p>
              </div>
            </div>
            <div className="bodyContent">
              <div
                className="bodyContentBG"
                style={{
                  position: "relative",
                  ...(maximizedWindow && { borderRadius: 10 }),
                }}
              >
                {callerDetails.appId !== "" ? (
                  <AgoraGroupCall
                    appId={callerDetails.appId}
                    channelId={callerDetails.channelName}
                    token={callerDetails.agoraToken}
                    UID={callerDetails.initiaterID}
                    typeofCall={callerDetails.typeofCall}
                    reciverId={callerDetails.receiverId}
                    twoLetters={callerDetails.twoLetters}
                    profilePicUrl={callerDetails.profilePicUrl}
                    role={callerDetails.role}
                    hospital={callerDetails.hospital}
                    name={callerDetails.name}
                    disconnectCallHandler={disconnectCallHandler}
                    callNotAnsweredHandler={callNotAnsweredHandler}
                  />
                ) : (
                  <div class="lds-spinner1">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default CallerWindow;
