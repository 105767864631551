import { mergeRight } from "ramda";

const initialState = {
  provider: {},
};

export default function providerDetailsReducer(state = initialState, action) {
  const { type, payload } = action;
  if (type === "SAVEPROVIDERDETAILS") {
    return {
      ...state,
      provider: payload,
    };
  } else if (type === "UPDATEPROVIDERDETAILS") {
    return {
      ...state,
      provider: mergeRight(state.provider, payload),
    };
  }
  return state;
}
