import produce from "immer";

const initialState = {
  teamDetails: [],
};

export default produce(function (draft, action) {
  if (typeof draft === "undefined") {
    return initialState;
  } else if (action.type === "SAVETEAMDETAILS") {
    draft.teamDetails = action.payload;
    return draft;
  }
  return draft;
});
