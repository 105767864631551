import axios from "axios";
import { toast } from "react-toastify";
import { LocalStorageGetValue, browserDetails } from "../Auth";

export const emailExpiredPassword = async (email, passwordNew, self) => {
  let token = JSON.parse(LocalStorageGetValue("user")).token;
  let browserName = browserDetails();
  try {
    const res = await axios.post(`/api/userEndpoints/v1/set/new/password`, {
      email,
      token,
      passwordNew,
      browserName: browserName,
      browserVersion: navigator.appVersion,
      osType: "Website",
    });
    self.setState({ loading: false });
    if (res.data.status) {
      self.props.history.push("/expired-success");
      window.history.replaceState(null, "", "/");
    } else {
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    console.log(error);
  }
};

export const phoneExpiredPassword = async (
  countryCode,
  phoneNumber,
  passwordNew,
  self
) => {
  let browserName = browserDetails();
  try {
    let token = JSON.parse(LocalStorageGetValue("user")).token;

    const res = await axios.post(`/api/userEndpoints/v1/set/new/password`, {
      countryCode,
      phoneNumber,
      token,
      passwordNew,
      browserName: browserName,
      browserVersion: navigator.appVersion,
      osType: "Website",
    });
    self.setState({ loading: false });
    if (res.data.status) {
      self.props.history.push("/expired-success");
      window.history.replaceState(null, "", "/");
    } else {
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    console.log(error);
  }
};
