import React from "react";
import { Route } from "react-router-dom";

const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (typeof Component === "object") {
        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      } else if (typeof Component === "function") {
        return <Layout {...props}>{Component(props)}</Layout>;
      }
    }}
  />
);

export default LayoutRoute;
