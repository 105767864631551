import produce from "immer";

const initialState = {
  vitalsData: [],
  vitalsOpen: false,
};

export default function VitalsReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "ADD_VITALS":
        draft.vitalsData = action.payload;
        break;
      case "EDIT_VITALS":
        const modifiedElement = draft.vitalsData.find(
          ({ key }) => key === action.vital
        );
        if (modifiedElement) {
          modifiedElement.value = action.value;
          modifiedElement.time = action.time;
        }
        break;
      case "VITALS_OPEN":
        draft.vitalsOpen = action.payload;
        break;
      default:
        break;
    }
  });
}
