import produce from "immer";

const initialState = {
  specialityTypes: [],
  vitalsDashboardSelectionState: {},
};

export default produce((draft, action) => {
  if (typeof draft === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case "SET_SPECIALITYDATA":
      draft.specialityTypes = action.payload;
      break;
    case "ADDVITALDASHBOARDSTATE":
      draft.vitalsDashboardSelectionState = action.payload;
      break;
    case "DELETEVITALDASHBOARDSTATE":
      draft.vitalsDashboardSelectionState = {};
      break;
  }
});
