const initialState = {
  incomingCallStatus: false,
};

const incomingCallStatus = (state = initialState, action) => {
  if (action.type === "incomingCall") {
    return {
      ...state,
      incomingCallStatus: action.payload.incomingCallStatus,
    };
  } else {
    return state;
  }
};

export default incomingCallStatus;
