export function increment() {
  return {
    type: "INCREMENT",
  };
}
export function decrement() {
  return {
    type: "DECREMENT",
  };
}
export function reset() {
  return { type: "RESET" };
}

export function show() {
  return {
    type: "SHOW",
  };
}

export function hide() {
  return {
    type: "HIDE",
  };
}
export const showmodel = (payload) => {
  return {
    type: "CALL_SHOW",
    payload,
  };
};
export const PatientCall = (payload) => {
  return {
    type: "patientCall",
    payload,
  };
};
export const showGroupModel = (payload) => ({
  type: "GROUP_CALL_SHOW",
  payload,
});
export const updateGroupProviderData = (payload) => ({
  type: "UPDATEGROUPPROVIDERDATA",
  payload,
});
export const groupCallTeamMembers = (payload) => ({
  type: "GROUP_TEAM_MEMBERS",
  payload,
});
export const expandCollapse = () => {
  return {
    type: "expandCollapse",
  };
};

export const showminimum = () => {
  return {
    type: "MINIMIZE",
  };
};
export const showmaximum = () => {
  return {
    type: "MAXIMIZE",
  };
};
export const showGroupCallminimum = () => {
  return {
    type: "GROUPCALLMINIMIZE",
  };
};
export const showGroupCallmaximum = () => {
  return {
    type: "GROUPCALLMAXIMIZE",
  };
};
export const showMessage = (type, open, message) => {
  return {
    type: type,
    showCallerMessage: open,
    message: message,
  };
};
export const resetCall = () => {
  return {
    type: "resetCall",
  };
};
export const callFeedBackShow = () => {
  return {
    type: "callFeedBackShow",
  };
};
export const callFeedBackHide = () => {
  return {
    type: "callFeedBackHide",
  };
};
