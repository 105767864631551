import produce from "immer";

const initialState = {
  AGORASUPPORTCODE: [],
};

export default produce(function (draft, action) {
  if (typeof draft === "undefined") {
    return initialState;
  } else if (action.type === "AGORA_SUPPORT_CODE") {
    draft.AGORASUPPORTCODE = action.payload;
    return draft;
  }
});
