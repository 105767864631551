import { createMuiTheme } from "@material-ui/core/styles";

const GlobalTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        color: "#172B4D !important",
        height: "40px !important",
        "& $notchedOutline,&:hover $notchedOutline": {
          border: "1px solid #5E6C84",
          borderRadius: "8px",
        },
        "&$focused $notchedOutline": {
          borderColor: "var(--primary-color)",
        },
        "&$disabled  $notchedOutline": {
          borderColor: "#5e6c84",
          border: "1px solid #5E6C84",
        },
        "& ::placeholder ": {
          fontFamily: "PoppinsRegular,  sans-serif",
          fontSize: "0.875rem",
          lineHeight: " 20px",
          display: "flex",
          alignItems: "center",
          letterSpacing: "-0.2px",

          color: "#5e6c84 !important",
        },
        "&$adornedEnd": {
          padding: 0,
        },
      },
      notchedOutline: {},
    },
    MuiFormHelperText: {
      root: {
        fontFamily: "PoppinsRegular,  sans-serif",
        fontSize: "0.75rem !important",
        lineHeight: "16px",
        letterSpacing: "-0.2px",
        color: "#5E6C84",
        margin: "0px !important",
        "&$error": {
          color: "#E52727",
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: "2px",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "var(--primary-color)",
        "&$checked": {
          color: "var(--primary-color)",
        },
      },
    },
    MuiBoxBoot: {
      padding: "2px",
    },
  },
});

export default GlobalTheme;
