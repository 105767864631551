export const LocalStorageClear = () => {
  sessionStorage.clear();
  localStorage.clear();
};
export const LocalStorageSetValue = (storageVariable, storageData) => {
  sessionStorage.setItem(storageVariable, storageData);
};
export const LocalStorageGetValue = (storageVariable) => {
  return sessionStorage.getItem(storageVariable);
};
export const ClearCache = () => {
  if ("caches" in window) {
    caches?.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
};
