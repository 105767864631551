import produce from "immer";

const initialState = {
  patientData: {},
  archiveSearchData: {
    searchInputValue: "",
    selectedValue: null,
    fromDateValue: undefined,
    toDateValue: undefined,
    showPatient: false,
  },
};

const archivePatientReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "ADDPATIENTDETAILS": {
        draft.patientData = action.payload;
        break;
      }
      case "ADDSELECTIONDETAILS": {
        draft.archiveSearchData = action.payload;
        break;
      }
      case "REMOVESELECTIONDETAILS": {
        draft.archiveSearchData = initialState.archiveSearchData;
        break;
      }
      case "REMOVEPATIENTDETAILS": {
        draft.patientData = {};
        break;
      }
      case "CLEARNAMEINPUTINSELECTIONDETAILS": {
        draft.archiveSearchData.searchInputValue = "";
        break;
      }
      case "CLEARTIMEINPUTINSELECTIONDETAILS": {
        draft.archiveSearchData.selectedValue = "";
        draft.archiveSearchData.fromDateValue = undefined;
        draft.archiveSearchData.toDateValue = undefined;
        break;
      }
      default:
        break;
    }
  });

export default archivePatientReducer;
