import axios from "axios";
import { toast } from "react-toastify";

export const getAllCountryCode = async () => {
  try {
    const res = await axios.get(`/api/userEndpoints/v1/getCountryList`);
    return res.data.countryCodeResponseList.map((item) => {
      const container = {};
      container.value = item.code;
      container.label = item.code;
      return container;
    });
  } catch (error) {
    return error;
  }
};

export const getAllRemoteProviderType = async () => {
  try {
    const res = await axios.get(`/api/userEndpoints/v1/getRemoteProviderType`);
    return res.data.remoteProviderTypeList.map((item) => {
      const container = {};
      container.value = item.type;
      container.label = item.name;
      container.remoteProviderId = item.id;
      container.remoteProviderType = item.type;
      container.userSubType = item.name;
      return container;
    });
  } catch (error) {
    return error;
  }
};

export const getTermsAndCondition = async () => {
  try {
    const res = await axios.get(`/api/userEndpoints/v1/termsAndCondition`);
    return res.data.configuration.value;
  } catch (error) {
    return error;
  }
};

export const getHospitalList = async () => {
  try {
    const res = await axios.get(`/api/userEndpoints/v1/getHospitals`);
    return res.data.hospitalList.map((item) => {
      const container = {};
      container.value = item.id;
      container.label = item.name;
      return container;
    });
  } catch (error) {
    return error;
  }
};

export const emailOrPhoneDuplicationCheck = async (ObjectForDublication) => {
  try {
    const res = await axios.post(
      `/api/userEndpoints/v1/emailOrPhoneDuplicationCheck`,
      ObjectForDublication
    );
    if (res.data.status) {
      return res;
    } else {
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    return error;
  }
};

export const registeration = async (objectMaking) => {
  try {
    const res = await axios.post(
      `/api/userEndpoints/v1/registerUser`,
      objectMaking
    );
    if (res.data.status) {
      return res;
    } else {
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    return error;
  }
};
export const sendOTPSMS = async (id, otp, self) => {
  try {
    const res = await axios.post(`/api/userEndpoints/v1/registrationVerify`, {
      id,
      otp,
      channel: "SMS",
    });
    self.setState({ loadingPhone: false });
    if (res.data.status) {
      setTimeout(() => self.setState({ countTime1: 24 }), 1000);
      self.setState({ phoneState: false });
      self.setState({ otp: "" });
    } else {
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const sendOTPEMAIL = async (id, otp, self) => {
  try {
    const res = await axios.post(`/api/userEndpoints/v1/registrationVerify`, {
      id,
      otp,
      channel: "EMAIL",
    });
    self.setState({ loadingEmail: false });
    if (res.data.status) {
      self.props.history.push("/register-success");
      window.history.replaceState(null, "", "/");
    } else {
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const resendPhoneOTP = async (id, code, phoneNumber) => {
  try {
    const res = await axios.post(`/api/userEndpoints/v1/registrationPhoneOTP`, {
      id,
      code,
      phoneNumber,
    });
    if (res.data.status) {
      toast.success("Sending OTP, Please wait");
    }
  } catch (error) {
    toast.error("Error Occured");
  }
};

export const resendEmailOTP = async (id, email) => {
  try {
    const res = await axios.post(`/api/userEndpoints/v1/registrationEmailOTP`, {
      id,
      email,
    });
    if (res.data.status) {
      toast.success("Sending OTP,Please wait");
    } else {
      toast.error("Error Occured");
    }
  } catch (error) {
    toast.error("Error Occured");
  }
};
