const initialState = {
  patient: {},
  patientInfoAutoFill: false,
  OperationMode: "",
  notificationCount: 0,
};

export default function patientDetailsReducer(state = initialState, action) {
  const { type, payload } = action;
  if (type === "SAVEPATIENTDETAILS") {
    return {
      ...state,
      patient: payload,
    };
  }
  if (type === "PATIENTINFOAUTOFILL") {
    return {
      ...state,
      patientInfoAutoFill: payload.patientInfoAutoFill,
      OperationMode: payload.OperationMode,
    };
  }
  if (type === "NOTIFICATIONCOUNT") {
    return {
      ...state,
      notificationCount: payload.notificationCount,
    };
  }
  return state;
}
