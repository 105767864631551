import axios from "axios";
import { sortBy, prop } from "ramda";
export function saveProviderDetails(payload) {
  return {
    type: "SAVEPROVIDERDETAILS",
    payload,
  };
}

export function updateProviderDetails(payload) {
  return {
    type: "UPDATEPROVIDERDETAILS",
    payload,
  };
}

export function setSpecialityData(payload) {
  return {
    type: "SET_SPECIALITYDATA",
    payload,
  };
}

export const specialityData = () => async (dispatch, getState) => {
  const {
    providerDetailsReducer: {
      provider: { userId: id },
    },
  } = getState();
  try {
    const {
      data: { specialtyTypes },
    } = await axios.post("api/commonEndpoints/v1/getAllSpecialtyTypes", { id });
    dispatch(setSpecialityData(sortBy(prop("order"), specialtyTypes)));
  } catch (error) {}
};
