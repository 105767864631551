const initialState = {
  inActive: false,
  name: "",
  errMsg: "",
};

const providerStatusReducer = (state = initialState, action) => {
  if (action.type === "providerAvailability") {
    return {
      ...state,
      inActive: action.payload.inActive,
      name: action.payload.name,
      errMsg: action.payload.errMsg,
    };
  } else {
    return state;
  }
};
export default providerStatusReducer;
