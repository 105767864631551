const initialState = {
  progress: false,
};

const progressENote = (state = initialState, action) => {
  if (action.type === "progress") {
    return {
      ...state,
      progress: action.payload,
    };
  } else {
    return state;
  }
};

export default progressENote;
