import debounce from "debounce-promise";
import { getConsultDetail } from "../../Services/ConsultsService";
import { getEconsults } from "../../Redux/Actions/econsultsAction.js";
import { LocalStorageGetValue } from "../../Services/Auth";
const debouncedgetConsultDetail = debounce(getConsultDetail, 100, {
  trailing: true,
});

export const SelectedWard = (payload) => {
  return {
    type: "selectedWard",
    payload,
  };
};
export const SelectedAcuity = (payload) => {
  return {
    type: "selectedAcuity",
    payload,
  };
};
export const SelectedMyActivePatient = (payload) => {
  return {
    type: "selectedMyActivePatient",
    payload,
  };
};
export const SelectedHospitals = (payload) => {
  return {
    type: "selectedHospitals",
    payload,
  };
};
export const SelectedPatients = (payload) => {
  return {
    type: "selectedPatients",
    payload,
  };
};
export const WardList = (payload) => {
  return {
    type: "wardList",
    payload,
  };
};
export const patientCensusPage = (payload) => {
  return {
    type: "isPrePatientCensus",
    payload,
  };
};
export const PatientList = (payload) => {
  return {
    type: "patientList",
    payload,
  };
};

export const UpdatePatientCensus = () => async (dispatch) => {
  const patientId = JSON.parse(LocalStorageGetValue("patientId"));
  const fetchEconsult = await debouncedgetConsultDetail(patientId);

  if (fetchEconsult?.patient) {
    dispatch(getEconsults(fetchEconsult.patient));
  }
};
