import React, { Component } from "react";

class Timer extends Component {
  state = {
    timerOn: false,
    timerStart: 0,
    timerTime: 0,
  };

  startTimer = () => {
    this.setState({
      timerOn: true,
      timerTime: this.state.timerTime,
      timerStart: Date.now() - this.state.timerTime,
    });
    this.timer = setInterval(() => {
      this.setState({
        timerTime: Date.now() - this.state.timerStart,
      });
    }, 10);
  };
  componentDidMount() {
    this.startTimer();
  }

  stopTimer = () => {
    this.setState({ timerOn: false });
    clearInterval(this.timer);
  };

  resetTimer = () => {
    this.setState({
      timerStart: 0,
      timerTime: 0,
    });
  };

  render() {
    const { timerTime } = this.state;
    let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    return (
      <div
        style={{ textAlign: "center", marginTop: "18px", marginLeft: "10px" }}
      >
        <div
          className="timeRun"
          style={{
            fontSize: "11px",
            fontFamily: "system-ui",
            fontStyle: "normal",
            color: "white",
            fontWeight: 400,
          }}
        >
          {minutes} : {seconds}
        </div>
      </div>
    );
  }
}

export default Timer;
