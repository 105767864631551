import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Divider } from "@material-ui/core";
import Urgent from "../../Assets/images/SVGS/Urgent 3.svg";
import "./MainLayout.scss";
import { logouts } from "../../Services/CommonService";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    height: "150px",
    width: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function LogoutAlert({ open, logout, self }) {
  const handlelogout = () => {
    self.setState({ logoutLoader: true });
    self.setState({ logoutAlertFlag: false });
    logouts(self);
  };
  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => logout()}
        >
          <Typography className="header-h3">{"Log Out"}</Typography>
        </BootstrapDialogTitle>
        <Divider style={{ color: "#EBECF0" }} />
        <DialogContent>
          <div className="logout-div">
            <img alt="alert" src={Urgent} />
            <Typography className="header-h4 logout-div1">
              {"Are you leaving?"}
            </Typography>
            <Typography className="captions-emphasis-2">
              {"Are you sure you want to log out?"}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                fontFamily: "PoppinsSemiBold",
                fontSize: "0.875rem",
                background: "white",
                border: "2px solid var(--primary-color)",
                boxSizing: "border-box",
                borderRadius: "8px",
                color: "var(--primary-color)",
                textTransform: "initial",
                width: "82px",
                height: "40px",
                marginRight: "12px",
              }}
              onClick={() => logout()}
            >
              {"Cancel"}
            </Button>
            <Button
              style={{
                fontFamily: "Poppins",
                fontWeight: "Bold",
                fontSize: "14px",
                background: "var(--primary-color)",
                border: "2px solid var(--primary-color)",
                boxSizing: "border-box",
                borderRadius: "8px",
                color: "white",
                textTransform: "initial",
                height: "40px",
              }}
              onClick={() => handlelogout()}
            >
              {"Log Out"}
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
