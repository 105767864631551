import produce from "immer";

const initialState = {
  zoomConference: false,
  zoomWindowOpen: false,
};

export default function ZoomReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "TOGGLEONLINECONFERENCE":
        draft.zoomConference = action.payload;
        break;
      case "ZOOMWINDOWOPEN":
        draft.zoomWindowOpen = action.payload;
        break;
      default:
        break;
    }
  });
}
