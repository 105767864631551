import produce from "immer";

const initialState = {
  econsults: {},
  patientId: null,
  pendingEconsultCount: 0,
  reconsults: {},
  eConsultPage: false,
  acuityFilter: "All",
  hospitalFilter: "All",
  statusFilter: "All",
};

export default produce(function (draft, action) {
  if (typeof draft === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case "reconsults":
    case "econsults": {
      draft.econsults = action.payload;
      return draft;
    }
    case "patientId": {
      draft.patientId = action.payload;
      return draft;
    }
    case "pendingEconsultCount": {
      draft.pendingEconsultCount = action.payload;
      return draft;
    }
    case "resetEconsults": {
      draft.econsults = {};
      return draft;
    }
    case "eConsultPage": {
      draft.eConsultPage = action.payload;
      return draft;
    }
    case "acuityFilter": {
      draft.acuityFilter = action.payload;
      return draft;
    }
    case "statusFilter": {
      draft.statusFilter = action.payload;
      return draft;
    }
    case "hospitalFilter": {
      draft.hospitalFilter = action.payload;
      return draft;
    }
    default:
      return draft;
  }
});
