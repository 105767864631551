const initialState = {
  showMini: false,
  showMaxi: true,
  showGroupCallMini: false,
  showGroupCallMaxi: true,
};

const CallMinMax = (state = initialState, action) => {
  switch (action.type) {
    case "MINIMIZE":
      if (document.getElementsByClassName("remoteVideoScreen").length > 0) {
        document.getElementsByClassName("remoteVideoScreen")[0].style.display =
          "none";
      }
      return {
        ...state,
        showMini: true,
        showMaxi: false,
      };
    case "MAXIMIZE":
      return {
        ...state,
        showMaxi: true,
        showMini: false,
      };
    case "GROUPCALLMINIMIZE":
      return {
        ...state,
        showGroupCallMini: true,
        showGroupCallMaxi: false,
      };
    case "GROUPCALLMAXIMIZE":
      return {
        ...state,
        showGroupCallMaxi: true,
        showGroupCallMini: false,
      };
    default:
      return state;
  }
};

export default CallMinMax;
