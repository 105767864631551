import produce from "immer";

const initialState = {
  providerProfilePics: [],
};

export default produce(function (draft, action) {
  if (typeof draft === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case "providerProfileImg": {
      draft.providerProfilePics = action.payload;
      return draft;
    }
    case "selfProviderDetails": {
      draft.providerProfilePics.push(action.payload);
      return draft;
    }
    default:
      return draft;
  }
});
