export const backgroundcolor = "#f8f8f8";
export const primarycolor1 = "#172b4d";
export const primarycolor2 = "var(--primary-color)";
export const primarycolor3 = "#010353";
export const primarycolor4 = "#ffffff";
export const primarycolor5 = "#000000";
export const secondarycolor1 = "#344563";
export const secondarycolor2 = "#5e6c84";
export const secondarycolor3 = "#5e6c84";
export const secondarycolor4 = "#344563";
export const secondarycolor5 = "#b3bac5";
export const secondarycolor6 = "#d1d6dd";
export const secondarycolor7 = "#ebecf0";
export const secondarycolor8 = "#eff1f5";
export const secondarycolor9 = "#e52727";
export const secondarycolor10 = "#f2994a";
export const secondarycolor11 = "#f2c94c";
export const secondarycolor13 = "#219653";
export const secondarycolor14 = "#27ae60";
export const secondarycolor15 = "#56c596";
export const secondarycolor16 = "#1366d6";
export const secondarycolor17 = "#2d9cdb";
export const secondarycolor18 = "#56ccf2";
export const secondarycolor19 = "#bb6bd9";
export const secondarycolor20 = "#1697d6";
export const secondarycolor21 = "#8044b9";
export const secondarycolor22 = "#ffffff";
export const secondarycolor23 = "#8993a4";
export const secondarycolor24 = "#f7f8fa";
export const secondarycolor25 = "#6f6f6f";
export const secondarycolor26 = "#D86500";
export const secondarycolor27 = "#D10000";
export const secondarycolor28 = "#030303";
export const secondarycolor29 = "#EB7B18";
export const secondarycolor30 = "#56C596";
