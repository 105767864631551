const initialState = {
  unsupportedBrowser: false,
};

export default function (state = initialState, action) {
  if (action.type) {
    return { ...state, unsupportedBrowser: true };
  }
  return state;
}
