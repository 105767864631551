import produce from "immer";

const initialState = {
  hospitalList: {},
  hospitalWardList: {},
};

export default produce((state, action) => {
  if (typeof state === "undefined") {
    return initialState;
  }

  switch (action.type) {
    case "hospitals":
      state.hospitalList = action.payload;
      break;
    case "wards":
      state.hospitalWardList = action.payload;
      break;
    default:
      break;
  }
}, initialState);
