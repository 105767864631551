export const getEconsults = (payload) => {
  return {
    type: "econsults",
    payload,
  };
};

export const getReconsults = (payload) => {
  return {
    type: "reconsults",
    payload,
  };
};
export const passPatientId = (payload) => {
  return {
    type: "patientId",
    payload,
  };
};
export const pendingeconsultCount = (payload) => {
  return {
    type: "pendingEconsultCount",
    payload,
  };
};

export const resetEconsultStorage = () => ({
  type: "resetEconsults",
});

export const eConsultPage = (payload) => ({
  type: "eConsultPage",
  payload,
});

export const acuityFilter = (payload) => {
  return {
    type: "acuityFilter",
    payload,
  };
};
export const statusFilter = (payload) => {
  return {
    type: "statusFilter",
    payload,
  };
};
export const hospitalFilter = (payload) => {
  return {
    type: "hospitalFilter",
    payload,
  };
};
